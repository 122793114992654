import * as React from 'react'
import { useGlobalState } from 'store'
import ReactPlayer from 'react-player'
import {
  Box,
  Grid,
  Paper,
} from '@mui/material'
export const Component = ({ props }) => {
  const globalState = useGlobalState()
  const { fb } = globalState


  if (!fb.main_image_url) return null
  return (
    <>
      <Paper
        sx={{
          padding: '12px',
          marginTop: '40px',
          marginLeft: '40px',
          marginRight: '40px'
        }}
      >
        <Grid container spacing={1} sx={{
          padding: '12px'
        }}>
          <Grid item xs={12}>
            <Paper sx={{
              padding: '8px',
              backgroundColor: 'primary.main',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}>
              <Box sx={{ textAlign: 'center' }}>
                {fb.main_image_url && fb.main_image_url.includes('.mp4')
                  ? <ReactPlayer
                    style={{
                      maxWidth: '100%'
                    }}
                    url={fb.main_image_url}
                    width='100%'
                    controls
                  />
                  : <Box
                    component='img'
                    src={fb.main_image_url}
                    sx={{
                      maxWidth: '100%'
                    }}
                    alt='Main'
                  />}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper >
    </>
  )
}

export default Component
