import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import APP_FORMBUILDER from 'apps/formbuilder/SimpleDonation'
import APP_WEBHOOK_AUTHORIZENET from 'apps/clientlist'
import APP_WEBHOOK_BRAINTREE from 'apps/clientlist'
import APP_WEBHOOK_PLAID from 'apps/clientlist'

import CC_ADMIN_LIST from 'apps/clientlist'
import CC_PRIVACY_POLICY from 'apps/legal/privacypolicy'
import CC_SWIPE_GIVE from 'apps/swipegive'
import CC_TERMS_OF_SERVICE from 'apps/legal/termsofservice'

import RIVAL_THERMOMETER from 'apps/embed/RivalThermometer'

const App = () => {

  if (window.location.pathname === '/') {
    if (process.env.NODE_ENV === 'production') {
      window.location.replace('https://www.caringcent.com')
      return
    }
  }

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/cc/list' element={<CC_ADMIN_LIST />} />
            <Route path='/swipegive' element={<CC_SWIPE_GIVE />} />
            <Route path='/privacypolicy' element={<CC_PRIVACY_POLICY />} />
            <Route path='/termsofservice' element={<CC_TERMS_OF_SERVICE />} />
            <Route path='/hooks/braintree' element={<APP_WEBHOOK_BRAINTREE />} />
            <Route path='/hooks/authorizenet' element={<APP_WEBHOOK_AUTHORIZENET />} />
            <Route path='/hooks/plaid' element={<APP_WEBHOOK_PLAID />} />
            <Route path='/rivals' element={<RIVAL_THERMOMETER />} />
            <Route path='/f' element={<APP_FORMBUILDER />} />
            <Route path='/' element={<CC_ADMIN_LIST />} />
            <Route path='*' element={<APP_FORMBUILDER />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </>
  )
}

export default App
if (process.env.NODE_ENV === 'development') {
  console.log('Running in development mode')
  console.log(process.env.REACT_APP_API_URL)
} else if (process.env.NODE_ENV === 'production') {
  console.log('Running in production mode')
  console.log(process.env.REACT_APP_API_URL)
}