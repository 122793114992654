import * as React from 'react'
import { useGlobalState } from 'store/useGlobal'
import Stepper from './Stepper'

import {
  Box,
  Paper,
  Typography
} from '@mui/material'

const gradient = 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(200,0,37,1) 35%, rgba(0,0,0,1) 100%)'

export const FormFields = React.forwardRef((props, ref) => {
  const globalState = useGlobalState()
  const { org_name } = globalState.page.config

  return (
    <>
      <Box
        sx={{
          background: gradient,
          paddingBottom: '40px'
        }}>
        <br />
        <br />
        <Paper
          sx={{
            padding: '12px',
            marginLeft: '40px',
            marginRight: '40px'
          }}
        >
          <Box sx={{ padding: '16px' }}>
            <Typography paragraph>
              Round up your credit and debit card purchases and donate the change to {org_name}.
              What may seem like small change to you can have a huge impact on the organizations and programs you care about.
              Getting started is easy and
              giving is hassle-free!
            </Typography>
          </Box>
          <Stepper />
        </Paper>
      </Box >
    </>
  )
})

export default FormFields
