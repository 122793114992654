import React from 'react'
import { useGlobal } from 'store'
import {
  Typography,
  Alert,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment
} from '@mui/material'
import DropDown from 'components/DropDown'

export const Component = ({ formName, ...props }) => {
  const [globalState, globalActions] = useGlobal()
  const { fb } = globalState
  const { errors, tipping } = globalState[formName]

  function onChange(event) {
    let { name, value, checked, type } = event.target

    if (type === 'checkbox') {
      value = checked
    }

    if (name === 'other_value') {
      const regex = /^\$?\d+(,\d{3})*(\.\d{1,2})?$/
      if (value === '' || regex.test(value)) {
        console.log('value=', value)
      }
    }

    tipping[name] = value

    globalActions.mergeState({
      [formName]: {
        tipping,
        errors
      }
    })
    globalActions.updateAmount()
    globalActions.updateLineItems()
  }

  function onBlur(event) {
    let { name, value } = event.target

    value = parseFloat(value).toFixed(2)
    if (isNaN(value)) {
      value = 0
    }

    tipping[name] = value

    globalActions.mergeState({
      [formName]: {
        tipping,
        errors
      }
    })
    globalActions.updateAmount()
    globalActions.updateLineItems()
  }
  const parseMe = fb.tipping_option_values.includes('{') ? fb.tipping_option_values : '{' + fb.tipping_option_values + '}'
  const tipping_values = fb.tipping_option_values ? JSON.parse(parseMe) : {}
  //invert keys and values of tipping_values
  const new_tipping_values = {}
  Object.keys(tipping_values).forEach(key => {
    new_tipping_values[tipping_values[key]] = key
  })
  new_tipping_values['Other'] = 'Other'

  console.log('tipping_values', new_tipping_values)
  return (
    <>
      <span error-id='tipping_error' />
      <Grid item xs={12} >
        {errors.tipping_error &&
          <Alert severity='error'>
            {errors.tipping_error}
          </Alert>}
        <Typography variant="h6" >
          <FormControlLabel
            control={
              <Checkbox
                checked={tipping.selected}
                onChange={onChange}
                color='primary'
                name='selected'
                label='Provide tip to CaringCent'
              />
            }
          />  {fb.tipping_display_text || 'Add a tip to help pay our fund raising fees'}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontSize: '12px' }}
        >
          {fb.tipping_help_text || ' We rely on the generosity of donors to help us run our campaign.Your tip will cover the processing and platform fee.'}
        </Typography>
      </Grid>

      {tipping.selected && <>
        <Grid item xs={6} >
          <DropDown
            name='option'
            onChange={onChange}
            values={new_tipping_values}
            value={tipping.option}
            error={errors.tipping_error}
            label=''
          />
        </Grid>
        {tipping.option === 'Other' &&
          <Grid item xs={6}>
            <TextField
              required
              name='other_value'
              label='Tip Amount'
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              placeholder="0.00"
              value={tipping.other_value}
              error={errors.tipping_error}
              helperText=''
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}

            />
          </Grid>}
      </>}

    </>
  )
}

export default Component
