import * as React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import TwitterShareButton from 'components/TwitterShareButton'
import FacebookShareButton from 'components/FacebookShareButton'
import ContactLink from 'components/ContactLink'
import { useGlobalState } from 'store'

const gradient = 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(200,0,37,1) 35%, rgba(0,0,0,1) 100%)'

export default function Component() {
  const globalState = useGlobalState()
  const {
    config
  } = globalState.page

  return (
    <>
      <Box sx={{
        background: gradient,
        paddingTop: '20px',
        paddingBottom: '40px'
      }}>
        <Paper
          sx={{
            padding: '12px',
            marginLeft: '40px',
            marginRight: '40px'
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{
                textAlign: 'center',
                backgroundColor: 'primary.main',
                color: 'white',
                padding: '16px'
              }}>
                <Typography variant='h4'>
                  Thank you for taking the Hoops Pledge
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ pading: '16px' }}>
                <Grid container>
                  <Grid item xs={12} align='center'>
                    <Box
                      component='img'
                      alt='University Logo'
                      src='https://s3.amazonaws.com/donate.resources/txst/BB+Confirmation+Page.jpg'
                      sx={{
                        width: '100%',
                        maxWidth: 500,
                        marginBottom: '15px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} align='center'>
                    <Typography paragraph>
                      Thank you for supporting {config.acronym} student-athletes' continued success!  You’ll receive a confirmation email outlining
                      your participation in the Hoops Pledge Program.  If you do not see this e-mail in your
                      inbox, please check your Spam or Junk folders.
                    </Typography>
                    <Typography paragraph>
                      Pledge donations will be processed at the beginning of the following month. If you made a one-time donation,
                      it has been processed immediately.  Your PayPal or Credit Card statement
                      will read {config.descriptor}.  If you have any questions about your RallyGive program participation, please <ContactLink>Contact Us</ContactLink>.
                    </Typography>
                    <Typography paragraph>
                      Go Bobcats!
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  margin: '1px',
                  '& a': {
                    width: '100%',
                    margin: '0'
                  }
                }}>
                <FacebookShareButton
                  url={config.share_url}
                  message={config.share_message}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  margin: '1px',
                  '& a': {
                    width: '100%',
                    margin: '0'
                  }
                }}>
                <TwitterShareButton
                  url={config.share_url}
                  message={config.share_message}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box >
    </>
  )
}
