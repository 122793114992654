import * as React from 'react'
import { useGlobal } from 'store'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'

import DropDown from 'components/FbDropDown'
import context from 'util/context'





export const Component = ({
  defaultValue,
  donationName,
  formName,
  helperText,
  label,
  orientation,
  secondaryText,
  showOther,
  values,
  dropdownOptions,
  dropdownLabel,
}) => {
  const [globalState, globalActions] = useGlobal()


  const donation = globalState[formName].donations[donationName] || {
    selected: false,
    text: '',
    option: '',
    value: ''
  }
  values = values ? JSON.parse(values.includes('{') ? values : '{' + values + '}') : {}

  function getLabel(value) {
    for (const [key, val] of Object.entries(values)) {
      if (val === value) return key
    }
    return ''
  }


  function onChange(event) {
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    donation.errors = false
    donation.other_error = false

    if (name === 'option') {
      donation.selected = true
      if (value === 'Other') {
        donation.value = defaultValue
      } else {
        donation.value = value
      }
    }
    if (context.isEmpty(values)) {
      donation.option = donation.value = defaultValue || 0
    }
    donation[name] = value
    donation.label = label
    if (name.includes('_dropdown')) {
      donation.dropdownLabel = dropdownLabel
      donation.dropdownOption = value
    }

    if (donation.option !== 'Other' && !donation.value) {
      donation.value = defaultValue
      donation.option = defaultValue
    } else if (donation.option === 'Other' && !donation.value) {
      donation.other_error = true
    }

    donation.text = getLabel(donation.option)
    if (!donation.selected) {
      donation.value = ''
      donation.option = ''
      donation.text = ''
      donation.label = ''
      donation.dropdownLabel = ''
      donation.dropdownOption = ''
      delete globalState[formName].donations[donationName]
    }

    globalActions.mergeState({
      [formName]: {
        donations: {
          [donationName]: donation
        }
      }
    })
    globalActions.updateAmount()
    globalActions.updateLineItems()
  }

  const spacing = { xs: 12, sm: 4, md: 2 }

  const orient = orientation !== 'vertical' ? { xs: 12, sm: 2 } : { xs: 12 }


  return (
    <>
      <span error-id={'donation_' + donationName + '_error'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={donation.selected}
                onChange={onChange}
                color='primary'
                name='selected'
              />
            }
            label={label}
            sx={{
              label: {
                fontWeight: '600',
                position: 'relative'
              }
            }}
          />
          {secondaryText && <Box sx={{
            position: 'relative',
            left: 30
          }}>{secondaryText}</Box>}
          {helperText && <Box sx={{
            position: 'relative',
            left: 30
          }}>{helperText}</Box>}
        </Grid>
        {!context.isEmpty(values) &&
          <Grid item xs={12}>
            <FormControl component='fieldset' sx={{ width: '100%' }}>
              <RadioGroup
                aria-label='donation'
                name='option'
                onChange={onChange}
                value={donation.option}
                sx={{ marginLeft: '35px' }}
              >
                <Grid container spacing={3}>
                  {Object.entries(values).map(val =>
                    <Grid key={val[1]} item {...orient}>
                      <FormControlLabel
                        value={val[1]}
                        control={<Radio color='primary' />}
                        label={val[0]}
                      />
                    </Grid>
                  )}
                  {showOther &&
                    <>
                      <Grid item {...spacing}>
                        <FormControlLabel
                          value='Other'
                          control={<Radio color='primary' />}
                          label='Other'
                        />
                      </Grid>
                      {donation.option === 'Other' &&
                        <Grid item {...spacing}>
                          <TextField
                            required
                            name='value'
                            label='Other Amount'
                            fullWidth
                            type='number'
                            onChange={onChange}
                            value={donation.value}
                            error={donation.other_error}
                            helperText='Required'
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>}
                    </>}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        }
        {dropdownOptions && <>
          <Grid item xs={12} sm={6}>
            <Box sx={{
              position: 'relative',
              left: 30
            }}>
              <DropDown
                name={'donation_' + donationName + '_dropdown'}
                required={donation.selected}
                values={dropdownOptions}
                label={dropdownLabel}
                onChange={onChange}
                value={donation.dropdownOption}
              />
            </Box>
          </Grid>
        </>}
      </Grid>
    </>
  )
}

export default Component
