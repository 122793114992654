import * as React from 'react'

import { useGlobal } from 'store'

import Recaptcha from 'components/Recaptcha'
import { Link } from 'react-router-dom'

import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography
} from '@mui/material'


export const FormFields = React.forwardRef((props, ref) => {
  const [globalState, globalActions] = useGlobal()

  const { status } = globalState
  const { form, errors, config } = globalState.page

  function onChange(event) {
    globalActions.setForm({
      formName: 'page',
      action: 'setConfig',
      name: 'is_processing',
      value: false
    })
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    globalActions.setForm({
      formName: 'page',
      action: 'set',
      name: name,
      value: value
    })
  }

  function onSubmit(event) {
    globalActions.submitContactForm()
  }

  if (status === 'CONTACT_CONFIRM') {
    return (
      <>
        <Box
          sx={{
            backgroundColor: 'primary.main',
            paddingBottom: '40px'
          }}>
          <br />
          <br />
          <Paper
            sx={{
              padding: '12px',
              marginLeft: '40px',
              marginRight: '40px'
            }}
          >
            Your message was received. We will respond within 1 business day.
          </Paper>
        </Box>
      </>
    )
  }
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          paddingBottom: '40px'
        }}>
        <br />
        <br />
        <Paper
          sx={{
            padding: '12px',
            marginLeft: '40px',
            marginRight: '40px'
          }}
        >
          <Grid container spacing={6} sx={{
            padding: '12px'
          }}>
            <Grid item xs={12} sm={6}>
              <TextField
                ref={ref}
                required
                id='first_name'
                name='first_name'
                label='First Name'
                value={form.first_name}
                fullWidth
                autoComplete='fname'
                onChange={onChange}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id='last_name'
                name='last_name'
                label='Last Name'
                value={form.last_name}
                fullWidth
                autoComplete='lname'
                onChange={onChange}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id='email_address'
                name='email_address'
                label='Email Address'
                value={form.email_address}
                fullWidth
                autoComplete='emailaddress'
                onChange={onChange}
                error={Boolean(errors.email_address)}
                helperText={errors.email_address}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='phone_number'
                name='phone_number'
                label='Phone Number'
                value={form.phone_number}
                fullWidth
                autoComplete='phone'
                onChange={onChange}
                error={Boolean(errors.phone_number)}
                helperText={errors.phone_number}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='message'
                name='message'
                label='How can we help?'
                value={form.message}
                fullWidth
                multiline
                rows={4}
                onChange={onChange}
                error={Boolean(errors.message)}
                helperText={errors.message}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <Recaptcha />
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary' onClick={onSubmit} disabled={config.is_processing}>
                {config.is_processing ? 'Processing...' : 'Submit'}
              </Button>
              <Typography component='div' align='right'>Return to <Link to='?' onClick={() => { globalActions.setStatus('') }}>Donation Form</Link> </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
})

export default FormFields
