import * as React from 'react'
import {
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Skeleton
} from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material'
import SeoHeaders from 'components/Seo'
import Header from './Header'
import TermsOfService from 'apps/legal/termsofservice'
import PrivacyPolicy from 'apps/legal/privacypolicy'
import Entry from './Entry'
import {
  CC
} from './Logos'
import { useGlobal } from 'store'
import context from 'util/context'
const parms = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xl: 3
}

const blank = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
export default function Component(props) {
  const [globalState, globalActions] = useGlobal()

  const {
    clients = {}
  } = globalState

  React.useEffect(() => {
    const isMounted = { mounted: true }
    globalActions.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-form-builder-clients',
      isMounted: isMounted,
      spinner: {
        content: 'Reading Data. Please Wait...'
      },

    })

    return () => { isMounted.mounted = false }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box sx={{
        // maxWidth: 'calc(768px + 16px * 2)',
        margin: '0 auto',
        display: 'flex',
        minHeight: '100%',
        padding: '16px 16px',
        flexDirection: 'column',
        backgroundImage: 'url(https://s3.amazonaws.com/donate.resources/cc/Web-site-front-page.png)'
      }}>
        <SeoHeaders />
        <Header />
        <Grid container spacing={6}>
          {context.isEmpty(clients) ?
            [...Array(12)].map((e, i) => {
              return (
                <React.Fragment key={'skeleton' + i}>
                  <Grid item {...parms}>
                    <Skeleton
                      variant="rectangular"
                      width='100%'
                      height='1000px'
                      sx={{
                        backgroundColor: '#F0FFFF',
                        borderRadius: '5px'
                      }}

                    />
                  </Grid>
                </React.Fragment>
              )
            }) : <>
              <Grid item {...parms}>
                <Paper
                  elevation={3}
                  sx={{
                    minHeight: '200px',
                    height: '100%',
                    textAlign: 'center',
                    padding: '10px',
                    position: 'relative',
                    margin: 'auto'
                  }}>
                  <Box
                    component='img'
                    src={CC}
                    alt='CaringCent'
                    sx={{
                      height: '70px',
                      maxWidth: '100%'
                    }} />
                  <Box
                    sx={{
                      position: 'relative',
                      left: -20,
                      width: '110%'
                    }}>
                    <Typography
                      component='div'
                      sx={{
                        width: '90%',
                        fontSize: '12px',
                        textAlign: 'left'
                      }}
                    >
                      <List>
                        <ListItem >
                          <ListItemIcon>
                            <FiberManualRecord fontSize='8pt' />
                          </ListItemIcon>
                          <a href='/swipegive' target='_blank' rel='noopener noreferrer'>
                            SwipeGive
                          </a>
                        </ListItem>
                        <ListItem >
                          <ListItemIcon>
                            <FiberManualRecord fontSize='8pt' />
                          </ListItemIcon>
                          <PrivacyPolicy
                            variant='link'
                          >
                            Privacy Policy
                          </PrivacyPolicy>
                        </ListItem>
                        <ListItem >
                          <ListItemIcon>
                            <FiberManualRecord fontSize='8pt' />
                          </ListItemIcon>
                          <TermsOfService
                            variant='link'
                          >
                            Terms Of Service
                          </TermsOfService>
                        </ListItem>
                      </List>
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              {Object.entries(clients).map(([ok, o]) => {
                return (
                  <React.Fragment key={'entry-container-' + ok}>
                    <Grid item {...parms}>
                      <Entry client={o} />
                    </Grid>
                  </React.Fragment>
                )
              })}
            </>
          }
        </Grid>
      </Box >
    </>
  )
}
