import * as React from 'react'
import context from 'util/context'
import { useGlobal } from 'store'
import {
  Typography,
  Grid,
  Container,
  LinearProgress
} from '@mui/material'


export default function Component() {
  const [globalState, globalActions] = useGlobal()
  const { left, right } = globalState
  const params = context.getParams()

  React.useEffect(() => {
    globalActions.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-thermometer-data',
      apiPayload: params,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!left) return
  if (!right) return

  return (
    <>
      <Container maxWidth="md">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography
              variant='h5'
              sx={{ color: left.primary_color }}
              align='center'
            >
              {new Intl.NumberFormat().format(left.thermometer.num_donors)} donors of {new Intl.NumberFormat().format(left.campaign_goal_donors)} donor goal
            </Typography>
            <br />
            <LinearProgress
              variant='determinate'
              value={Math.ceil(100 * left.thermometer.num_donors / left.campaign_goal_donors)}
              sx={{
                transform: 'scale(-1, 1)',
                height: 20,
                borderRadius: 5,
                backgroundColor: 'lightgrey !important',
                '& .MuiLinearProgress-bar1Determinate': {
                  backgroundColor: left.primary_color + ' !important',
                },
              }}
            />
            <Typography
              align='left'
              sx={{ color: left.primary_color }}
            >
              {left.client} {Math.ceil(100 * left.thermometer.num_donors / left.campaign_goal_donors)}%
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant='h5'
              align='center'
              sx={{ color: right.primary_color }}
            >
              {new Intl.NumberFormat().format(right.thermometer.num_donors)} donors of {new Intl.NumberFormat().format(right.campaign_goal_donors)} donor goal
            </Typography>
            <br />
            <LinearProgress
              variant='determinate'
              value={Math.ceil(100 * right.thermometer.num_donors / right.campaign_goal_donors)}
              sx={{
                height: 20,
                borderRadius: 5,
                backgroundColor: 'lightgrey !important',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: right.primary_color + ' !important',
                },
              }}
            />
            <Typography
              align='right'
              sx={{ color: right.primary_color }}
            >
              {right.client} {Math.ceil(100 * right.thermometer.num_donors / right.campaign_goal_donors)}%
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}