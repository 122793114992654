import { API } from 'aws-amplify'
import awsconfig from 'aws-exports'
import context from 'util/context'
import { md5 } from 'util/md5'
import * as Yup from 'yup'

const __API__ = {
    Events: awsconfig.aws_cloud_logic_custom[0].name
}

const getApiIdentifier = (name) => {
    return __API__[name]
}

const schema = Yup.object().shape({
    first_name: Yup.string()
        .max(50, 'Your name must be less than 50 characters')
        .required('Required'),
    last_name: Yup.string()
        .max(50, 'Your name must be less than 50 characters')
        .required('Required'),
    email_address: Yup.string()
        .email('Invalid email')
        .required('Required'),
    street_address: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    postal_code: Yup.string()
        .min(5, 'Too Short!')
        .max(10, 'Too Long!')
        .required('Required'),
    recaptcha_token: Yup.string()
        .required('Please check the box below')
})

const running = {}

const actions = (store) => ({
    reset: () => {
        store.resetState()
    },
    performApi: async ({
        apiName,
        apiPath,
        apiAction,
        apiPayload = {},
        callback = (response) => { },
        stateReducer = (response) => { actions(store).mergeState(response) }
    }) => {
        const hash = md5(JSON.stringify(apiPayload))
        const runningKey = apiAction + '-' + hash
        console.log(`NEW ${apiName}  ${apiPath}  ${apiAction}\t\t\t(${hash})`)
        running[runningKey] = true
        const init = {
            body: {
                env: process.env.NODE_ENV,
                action: apiAction,
                version: 'v1',
                ts: new Date().toISOString(),
                payload: apiPayload
            }
        }
        try {
            const response = await API.post(getApiIdentifier(apiName), apiPath, init)
            callback(response)
            stateReducer(response)
        } catch (error) {
            running[runningKey] = false
            if (API.isCancel(error)) {
                console.log(error.message)
            } else if (error && error.response && error.response.data && error.response.data.python_exception) {
                context.handlePythonException(error.response.data.python_exception)
            } else if (error && error.response && error.response.data && error.response.data.message) {
                context.handleApiError(error.response.data)
                console.log(error)
            } else {
                console.error('Unhandled Error from API call')
                console.log(error)
                throw error
            }
        }
    },
    setPageConfig: (config) => {
        const newState = {
            page: {
                config: config
            }
        }
        const query = context.getQuery()
        for (const [key, value] of query) {
            if (key === 'page' && value) {
                newState.status = value.toUpperCase()
            }
        }
        actions(store).mergeState(newState)
    },
    setStatus: (status) => {
        actions(store).mergeState({ status: status.toUpperCase() })
    },
    setForm: async (action) => {
        actions(store).mergeState(actions(store).setFormReducer(action))
    },
    setFormReducer: (action) => {
        let {
            form,
            errors,
            config
        } = store.state[action.formName]

        if (action.name === 'recaptcha_token') {
            if (action.value === null) {
                action.value = ''
            }
        }

        switch (action.action) {
            case 'set':
                form[action.name] = action.value
                break
            case 'setConfig':
                config[action.name] = action.value
                break
            case 'setError':
                errors[action.name] = action.value
                break
            case 'loadForm':
                form = { ...action.form }
                break
            case 'clear':
                if (form.hasOwnProperty(action.name)) {
                    form[action.name] = ''
                }
                if (errors.hasOwnProperty(action.name)) {
                    errors[action.name] = ''
                }
                break
            case 'clearAll':
                for (const key in form) {
                    if (form.hasOwnProperty(key)) {
                        form[key] = ''
                    }
                }
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        errors[key] = ''
                    }
                }
                break
            default:
                break
        }

        return { [action.formName]: { form, errors, config } }
    },
    submitSendReceipt: async () => {
        actions(store).performApi({
            apiName: 'Events',
            apiPath: '/event',
            apiAction: 'send-receipt',
            apiPayload: { ...store.state }
        })
    },
    updateAmount: async () => {
        const page = {}
        const errors = {
            tipping_error: ''
        }
        let total_charge_amount = 0
        if (store.state.page.spot.value) {
            total_charge_amount += parseFloat(store.state.page.spot.value)
        }
        Object.entries(store.state.page.donations).forEach(([key, item]) => {
            if (item.selected && item.value) {
                total_charge_amount += parseFloat(item.value)
            }
        })
        if (store.state.page.tipping.selected === true && store.state.page.tipping.option !== '') {
            let tipping_amount = 0
            if (store.state.page.tipping.option === 'Other') {
                tipping_amount = parseFloat(store.state.page.tipping.other_value)

            } else {
                const temp = store.state.page.tipping.option
                if (temp.includes('$')) {
                    tipping_amount = parseFloat(temp.replace('$', ''))
                }
                if (temp.includes('%')) {
                    tipping_amount = parseFloat(temp.replace('%', '')) * total_charge_amount / 100
                }
            }
            if (isNaN(tipping_amount)) {
                tipping_amount = 0
            }
            total_charge_amount += tipping_amount
            page.tipping = {
                tip_amount_display: '$' + tipping_amount.toFixed(2)
            }
        }
        if (total_charge_amount < 5) {
            page.spot = {
                value_error: 'There is a $5 minimum donation.'
            }
            page.form = {
                total_charge_amount: 0
            }
        } else {
            page.form = {
                total_charge_amount: total_charge_amount.toString(10)
            }
        }

        //if tipping option is other and value is not a number, set error
        if (store.state.page.tipping.selected === true) {
            if (store.state.page.tipping.option === 'Other') {
                if (isNaN(store.state.page.tipping.other_value)) {
                    errors.tipping_error = 'Please enter a valid amount'
                }
                if (store.state.page.tipping.other_value < 1) {
                    errors.tipping_error = 'Please enter an amount greater than $1'
                }
            } else if (store.state.page.tipping.option === '') {
                errors.tipping_error = 'Please select a tipping option from the dropdown below'
            }
        }
        page.errors = errors
        actions(store).mergeState({ page })
    },
    createPlaceHolder: (name) => {
        return async (...args) => {
            console.log('This is a placeholder for function `' + name + '` that should be replaced by a proper event handler.')
            args.forEach((arg) => {
                console.log('argument: ' + arg)
                console.log(arg)
            })
        }
    },
    plaidOnSuccess: async (key, result) => {
        actions(store).mergeState({
            plaid: {
                key: key,
                account: result.account,
                institution: result.institution
            }
        })
    },
    updateLineItems: async () => {

        // Braintree Line Item keys
        // const item_keys = [
        //   'quantity',
        //   'name',
        //   'description',
        //   'kind',
        //   'unit_amount',
        //   'unit_tax_amount',
        //   'discount_amount',
        //   'total_amount',
        //   'unit_of_measure',
        //   'product_code',
        //   'commodity_code',
        //   'url',
        //   'tax_amount',
        // ]



        const line_items = {
            braintree: [],
            paypal: []
        }

        if (store.state.page.spot.value) {
            line_items.braintree.push({
                quantity: 1,
                name: 'Spot Donation',
                description: 'Spot Donation',
                kind: 'debit',
                unit_amount: context.round(store.state.page.spot.value),
                total_amount: context.round(store.state.page.spot.value),
                product_code: 'SPOT',
                commodity_code: 'SPOT'
            })
            line_items.paypal.push({
                quantity: 1,
                name: 'Spot Donation',
                description: 'Spot Donation',
                kind: 'debit',
                unit_amount: context.round(store.state.page.spot.value),
                total_amount: context.round(store.state.page.spot.value),
                product_code: 'SPOT'
            })
        }

        Object.entries(store.state.page.donations).forEach(([key, item]) => {
            let qty = item.qty || 1
            let unit_amount = parseFloat(item.value || .01)
            let total_amount = qty * unit_amount
            let name = item.label || item.text
            let description = item.text || item.label
            if (item.selected) {
                if (item.label) {
                    line_items.braintree.push({
                        quantity: qty.toString(),
                        name: name.substr(0, 35),
                        description: description.substr(0, 127),
                        kind: 'debit',
                        unit_amount: context.round(unit_amount),
                        total_amount: context.round(total_amount),
                        product_code: 'DONATION',
                    })
                    line_items.paypal.push({
                        quantity: qty.toString(),
                        name: name,
                        description: description,
                        kind: 'debit',
                        unit_amount: context.round(unit_amount),
                        total_amount: context.round(total_amount),
                        product_code: 'DONATION'
                    })
                    if (unit_amount === .01) {
                        line_items.paypal.push({
                            quantity: qty.toString(),
                            name: name,
                            description: description,
                            kind: 'credit',
                            unit_amount: context.round(unit_amount),
                            total_amount: context.round(total_amount),
                            product_code: 'DONATION'
                        })
                    }
                }
                if (item.dropdownLabel) {
                    name = item.dropdownLabel
                    description = item.dropdownOption
                    line_items.braintree.push({
                        quantity: qty.toString(),
                        name: name.substr(0, 35),
                        description: description.substr(0, 127),
                        kind: 'debit',
                        unit_amount: context.round(.01),
                        total_amount: context.round(.01),
                        product_code: 'NOTATION',
                    })
                    line_items.braintree.push({
                        quantity: qty.toString(),
                        name: name.substr(0, 35),
                        description: description.substr(0, 127),
                        kind: 'credit',
                        unit_amount: context.round(.01),
                        total_amount: context.round(.01),
                        product_code: 'NOTATION',
                    })
                    line_items.paypal.push({
                        quantity: qty.toString(),
                        name: name,
                        description: description,
                        kind: 'debit',
                        unit_amount: context.round(.01),
                        total_amount: context.round(.01),
                        product_code: 'NOTATION'
                    })
                    line_items.paypal.push({
                        quantity: qty.toString(),
                        name: name,
                        description: description,
                        kind: 'credit',
                        unit_amount: context.round(.01),
                        total_amount: context.round(.01),
                        product_code: 'NOTATION'
                    })
                }
            }
        })

        Object.entries(store.state.page.metrics).forEach(([key, item]) => {
            let qty = item.qty || 1
            let unit_amount = parseFloat(item.value || .01)
            let total_amount = qty * unit_amount
            let name = item.label || item.text
            let description = item.text || item.label
            if (item.selected) {
                line_items.braintree.push({
                    quantity: qty.toString(),
                    name: name.substr(0, 35),
                    description: description.substr(0, 127),
                    kind: 'debit',
                    unit_amount: context.round(unit_amount),
                    total_amount: context.round(total_amount),
                    product_code: 'RALLY',
                })
                line_items.paypal.push({
                    quantity: qty.toString(),
                    name: name,
                    description: description,
                    kind: 'debit',
                    unit_amount: context.round(unit_amount),
                    total_amount: context.round(total_amount),
                    product_code: 'RALLY'
                })
                line_items.paypal.push({
                    quantity: qty.toString(),
                    name: name,
                    description: description,
                    kind: 'credit',
                    unit_amount: context.round(unit_amount),
                    total_amount: context.round(total_amount),
                    product_code: 'RALLY'
                })
            }
        })

        actions(store).mergeState({ page: { line_items } })
    },
    mergeState: (newState) => {
        store.setState(context.recursiveAssign(context.copy(store.state), newState))
    },
    submitContactForm: async () => {
        const newErrors = {}
        let okToSubmit = true

        const {
            form
        } = store.state.page

        const schema = Yup.object().shape({
            first_name: Yup.string()
                .max(50, 'Your name must be less than 50 characters')
                .required('Required'),
            last_name: Yup.string()
                .max(50, 'Your name must be less than 50 characters')
                .required('Required'),
            email_address: Yup.string()
                .email('Invalid email')
                .required('Required'),
            message: Yup.string()
                .required('You forgot to tell us how we can help you!'),
            recaptcha_token: Yup.string()
                .required('Please check the box below')
        })

        try {
            await schema.validate(form, { abortEarly: false })
        } catch (err) {
            err.inner.forEach((element) => {
                newErrors[element.path] = element.message
                okToSubmit = false
            })
        }

        actions(store).mergeState({ page: { errors: newErrors } })

        if (okToSubmit) {
            const payload = {
                ...store.state
            }

            actions(store).setForm({
                formName: 'page',
                action: 'setConfig',
                name: 'is_processing',
                value: true
            })

            actions(store).performApi({
                apiName: 'Events',
                apiPath: '/event',
                apiAction: 'contact-us',
                apiPayload: payload,
                callback: response => {
                    actions(store).setForm({
                        formName: 'page',
                        action: 'setConfig',
                        name: 'is_processing',
                        value: false
                    })
                    if (response.status === 'PAYMENT_SUCCESS') {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    }
                }
            })
        }
    },

    submitDonateForm: async (braintree_instance, schemaParam = schema) => {
        let nonce
        const newErrors = {}
        let okToSubmit = true

        const {
            errors,
            form,
            metrics,
            donations,
            spot,
            config,
            tipping
        } = store.state.page

        for (const [key] of Object.entries(errors)) {
            newErrors[key] = ''
        }

        try {
            nonce = await braintree_instance.requestPaymentMethod()
        } catch (error) {
            actions(store).setForm({
                formName: 'page',
                action: 'setConfig',
                name: 'is_processing',
                value: false
            })
            if (error.message) {
                let message
                if (error.message === 'No payment method is available.') {
                    message = 'Please enter a payment method below'
                } else {
                    message = error.message
                }
                newErrors.payment_method = message
            } else {
                newErrors.payment_method = error
            }
            okToSubmit = false
        }
        let num = 0
        if (spot.selected) {
            num++
            if (spot.value) {
                if (spot.value < 5) {
                    spot.value_error = 'There is a $5 minimum donation.'
                    newErrors.spot_donation = 'There is a $5 minimum donation.'
                    okToSubmit = false
                } else if (spot.value === '35' || spot.value === '50') {
                    if (!form.extra_info || form.extra_info.length === 0) {
                        newErrors.extra_info = 'Please select an option'
                    }
                }
            } else {
                spot.value_error = 'Required'
                newErrors.spot_donation = 'There is a $5 minimum donation.'
                okToSubmit = false
            }
        }
        // eslint-isable-next-line
        for (const [key, metric] of Object.entries(metrics)) {
            if (metric.selected === true) {
                num++
                if (!metric.value) {
                    newErrors.metric_error = 'A dollar value is required for all selected items'
                    okToSubmit = false
                }
            }
        }
        // eslint-disable-next-line
        for (const [key, donation] of Object.entries(donations)) {
            if (donation.selected === true) {
                num++
                if (!donation.dropdownLabel && !donation.value) {
                    newErrors.donation_error = 'A dollar value is required for all selected items'
                    okToSubmit = false
                }
            }
        }
        form.special_fund_required = config.special_fund_required
        try {
            await schemaParam.validate(form, { abortEarly: false })
        } catch (err) {
            err.inner.forEach((element) => {
                newErrors[element.path] = element.message
                okToSubmit = false
            })
        }
        if (num === 0) {
            newErrors.metric_error = 'Please select a way to donate.'
            okToSubmit = false
        }
        if (false) {
        } else if (newErrors.donation_1_dropdown) {
            context.animateScrollTo('[error-id="donation_1_error"]')
        } else if (newErrors.donation_2_dropdown) {
            context.animateScrollTo('[error-id="donation_2_error"]')
        } else if (newErrors.donation_3_dropdown) {
            context.animateScrollTo('[error-id="donation_3_error"]')
        } else if (newErrors.donation_4_dropdown) {
            context.animateScrollTo('[error-id="donation_4_error"]')
        } else if (newErrors.donation_5_dropdown) {
            context.animateScrollTo('[error-id="donation_5_error"]')
        } else if (newErrors.donation_6_dropdown) {
            context.animateScrollTo('[error-id="donation_6_error"]')
        } else if (newErrors.donation_7_dropdown) {
            context.animateScrollTo('[error-id="donation_7_error"]')
        } else if (newErrors.donation_8_dropdown) {
            context.animateScrollTo('[error-id="donation_8_error"]')
        } else if (newErrors.donation_9_dropdown) {
            context.animateScrollTo('[error-id="donation_9_error"]')
        } else if (newErrors.first_name ||
            newErrors.last_name ||
            newErrors.email_address ||
            newErrors.street_address ||
            newErrors.postal_code) {
            context.animateScrollTo('label[for="first_name"]')
        } else if (newErrors.special_fund || newErrors.special_fund_notes) {
            context.animateScrollTo('[error-id="special_fund"]')
        } else if (newErrors.spot_donation || newErrors.extra_info) {
            context.animateScrollTo('[error-id="spot_donation"]')
        } else if (newErrors.metric_error) {
            context.animateScrollTo('[error-id="metric_error"]')
        } else if (newErrors.payment_method) {
            context.animateScrollTo('[error-id="payment_method"]')
        } else if (newErrors.recaptcha_token) {
            context.animateScrollTo('[error-id="recaptcha_token"]')
        } else if (newErrors.tipping_error) {
            context.animateScrollTo('[error-id="tipping_error"]')
        }
        actions(store).mergeState({ page: { errors: newErrors } })

        if (okToSubmit) {
            const payload = {
                ...store.state,
                braintree_nonce: nonce,
                dom: document.getElementsByTagName('html')[0].innerHTML
            }
            let apiAction = 'formbuilder-submit'
            actions(store).setForm({
                formName: 'page',
                action: 'setConfig',
                name: 'is_processing',
                value: true
            })

            actions(store).performApi({
                apiName: 'Events',
                apiPath: '/event',
                apiAction: apiAction,
                apiPayload: payload,
                callback: response => {
                    console.log(response)
                    actions(store).setForm({
                        formName: 'page',
                        action: 'setConfig',
                        name: 'is_processing',
                        value: false
                    })
                    if (response.status === 'PAYMENT_SUCCESS') {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    }
                }
            })
        }
    }


})


export default actions