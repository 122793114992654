import * as React from 'react'
import { useGlobal } from 'store'
import context from 'util/context'
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
} from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material'
import NOT_AVAILABLE from 'assets/images/Image_not_available.png'
import useVisibleEffect from 'util/onVisibleHook'


export default function Component({ client, ...props }) {
  const [globalState, globalActions] = useGlobal()
  const entries = globalState.forms[client.client_id] || []

  const targetRef = useVisibleEffect(() => {
    // Your side effect when the component becomes visible
    if (context.isEmpty(entries)) {
      globalActions.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-form-builder-meta-data',
        apiPayload: {
          client: client.client_id,
        },
        spinner: {
          content: 'Reading Data. Please Wait...'
        }
      })
    }
  }, { threshold: 0.5 })
  // React.useEffect(() => {
  //   const isMounted = { mounted: true }
  //   globalActions.performApi({
  //     apiName: 'Events',
  //     apiPath: '/event',
  //     apiAction: 'get-form-builder-meta-data',
  //     apiPayload: {
  //       client: client.client_id,
  //     },
  //     isMounted: isMounted,
  //     spinner: {
  //       content: 'Reading Data. Please Wait...'
  //     }
  //   })

  //   return () => { isMounted.mounted = false }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Paper
        ref={targetRef}
        elevation={3}
        sx={{
          minHeight: '200px',
          height: '100%',
          textAlign: 'center',
          padding: '10px',
          position: 'relative',
          margin: 'auto'
        }}>
        <Box sx={{
          height: '35px',
          fontSize: '.9em'
        }}>{client.client_id}</Box>

        <Box
          component='img'
          src={client.logo || NOT_AVAILABLE}
          alt='Logo'
          sx={{
            height: '70px',
            maxWidth: '100%'
          }} />
        <Box sx={{
          position: 'relative',
          left: -20,
          width: '110%'
        }}>
          <Typography
            sx={{
              width: '90%',
              fontSize: '12px',
              textAlign: 'left'
            }}
            component='div'>
            <List>
              {entries.map((item) => {
                item.host = item.host ? item.host : 'https://caringcent.org'
                return (
                  <ListItem key={'customer-' + item.sys_id}>
                    <ListItemIcon>
                      <FiberManualRecord fontSize='8pt' />
                    </ListItemIcon>
                    <Box>
                      <Box sx={{
                        color: '#999', // Light gray color
                        fontSize: '10px', // Adjust the font size as needed
                      }} >
                        Created: {context.formatDate(item.sys_created)}
                      </Box>
                      <Box>
                        <a href={item.url_slug} target='_blank' rel='noopener noreferrer'>
                          {item.campaign || '<No Campaign Provided>'}
                        </a>&nbsp;&nbsp;
                        <a href={'https://caringcent.org' + item.url_slug} target='_blank' rel='noopener noreferrer'>
                          (P)
                        </a>
                      </Box>
                    </Box>
                  </ListItem>
                )
              })}
            </List>

          </Typography>
        </Box>
      </Paper >
    </>
  )
}
