import * as React from 'react'
import { useLocation } from 'react-router-dom'
import context from 'util/context'
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  CssBaseline
} from '@mui/material'
import { useGlobal } from 'store'

import FormBody from './FormBody'
import SeoHeaders from './Seo'
import LoadingIndicator from 'components/LoadingIndicator'

export default function Component() {
  const [globalState, globalActions] = useGlobal()

  const { fb } = globalState
  const params = context.getParams()
  const location = useLocation()

  if (!params || !params.client || !params.campaign) {
    const parts = location.pathname.split('/')
    params.client = parts[1]
    params.campaign = parts[2]
    params.pathname = location.pathname
  }
  React.useEffect(() => {
    if (fb) return
    globalActions.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-form-builder',
      apiPayload: params,
      callback: (response) => {
        if (response.fb?.config) {
          globalActions.setPageConfig({
            ...globalState.config,
            ...response.fb.config
          })
        } else if (response.redirect) {
          context.redirect(response.redirect)
        }
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (typeof params.page === 'string' && params.page.toLowerCase() === 'contact') {
      globalActions.setStatus('CONTACT')
    }
  }, [globalActions, params.page])

  if (!fb) {
    return (
      <>
        <LoadingIndicator />
      </>
    )
  }

  const default_font = 'Open Sans, sans-serif'

  const theme = responsiveFontSizes(createTheme({
    palette: {
      primary: { main: fb.primary_color || '#000000' },
      secondary: { main: fb.secondary_color || '#A0A0A0' }
    },
    typography: {
      fontFamily: fb.text_font || default_font,
      body2: { fontSize: '1rem' },
      h1: { fontFamily: fb.header_font || default_font },
      h2: { fontFamily: fb.header_font || default_font },
      h3: { fontFamily: fb.header_font || default_font },
      h4: { fontFamily: fb.header_font || default_font }
    },
    // overrides: {
    //   MuiCssBaseline: {
    //     '@global': {
    //       '@font-face': CustomFontDefs
    //     }
    //   }
    // }
  }))

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SeoHeaders />
        <FormBody />
      </ThemeProvider>
    </>
  )
}
