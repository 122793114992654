import * as React from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useGlobal } from 'store'

import {
  Button,
  Grid
} from '@mui/material'

export default function Component() {
  const [globalState, globalActions] = useGlobal()

  const { plaid } = globalState

  React.useEffect(() => {
    globalActions.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-plaid-token'
    })
  }, [globalActions])

  const config = {
    clientName: 'CaringCent',
    token: plaid.plaid_link_token,
    onSuccess: globalActions.plaidOnSuccess,
    // onExit: globalActions.createPlaceHolder('onExit'),
    // onLoad: globalActions.createPlaceHolder('onLoad'),
    // onEvent: globalActions.createPlaceHolder('onEvent'),
    env: plaid.env,
    linkCustomizationName: 'amplify_swipe_give',
    product: ['transactions'],
    accountSubtypes: {
      credit: ['all']
    }
  }

  const { open, ready, error } = usePlaidLink(config)

  return (
    <>
      {error && <>{error}</>}
      <br />
      <Button
        variant='contained'
        color='primary'
        onClick={() => open()}
        disabled={!ready}
      >
        Connect a round up account
      </Button>
      <Grid container spacing={1} sx={{
        padding: '12px'
      }}>
        <Grid item xs={12} sm={6}>
          <b>Issuing Bank:</b> {plaid.institution && plaid.institution.name}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Type:</b>  {plaid.account && plaid.account.subtype}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Name:</b>  {plaid.account && plaid.account.name}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Last 4:</b>  {plaid.account && plaid.account.mask}
        </Grid>
      </Grid>

    </>
  )
}
