import * as React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import Body from './Content'
import { useGlobal } from 'store'

export default function ScrollDialog(props) {
  const [globalState, globalActions] = useGlobal()

  const { toggles } = globalState

  const handleClickOpen = () => {
    globalActions.mergeState({
      toggles: {
        ShowTermsOfService: true,
        ShowPrivacyPolicy: false,
      }
    })
  }

  const handleClose = () => {
    globalActions.mergeState({
      toggles: {
        ShowTermsOfService: false
      }
    })
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (toggles.ShowTermsOfService) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [toggles.ShowTermsOfService])

  const Link = () => {
    if (props.variant && props.variant.toLowerCase() === 'link') {
      return <>
        <Box
          component='span'
          onClick={handleClickOpen}
          sx={{
            background: 'none !important',
            border: 'none',
            padding: '0 !important',
            color: '#069',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>
          {props.children}
        </Box>
      </>
    } else {
      return <>
        <Button
          onClick={handleClickOpen}
          color="primary"
        >
          {props.children}
        </Button>
      </>
    }
  }

  return (
    <Box component='span'>
      <Link />
      <Dialog
        open={toggles.ShowTermsOfService}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          CaringCent Terms of Service
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Body />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
