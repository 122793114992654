import * as React from 'react'
import { useGlobalState } from 'store'
import {
  Box,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import RawHtml from 'components/RawHtml'

export default function Component({ props }) {
  const globalState = useGlobalState()
  const { fb } = globalState

  if (!fb.body_message_text && !fb.body_header_url) return null
  return (
    <>
      <Paper
        sx={{
          padding: '12px',
          marginTop: '40px',
          marginLeft: '40px',
          marginRight: '40px'
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {fb.body_header_url ? <Box sx={{
              textAlign: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
              padding: '16px'
            }}>
              <Box component='img'
                src={fb.body_header_url}
                alt={'Message Header'}
                sx={{ width: '100%' }}
              />
            </Box>
              : <Box
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  padding: '16px'
                }}>
                <Typography variant='h4'>
                  {fb.body_header_text}
                </Typography>
              </Box>}
            {fb.body_message_text &&
              <RawHtml
                content={fb.body_message_text}
                sx={{ padding: '16px' }}
              />
            }
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
