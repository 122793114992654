import * as React from 'react'
import { useGlobalActions } from 'store'
import { Box } from '@mui/material'

export function ContactLink(props) {
  const { globalActions } = useGlobalActions()

  const handleClickOpen = () => {
    globalActions.setStatus('contact')
    window.scrollTo(0, 0)
  }

  if (props.variant && props.variant.toLowerCase() === 'button') {
    return (
      <>
        <button type='button' onClick={handleClickOpen}>
          {props.children}
        </button>
      </>
    )
  } else {
    return (
      <>
        <Box
          component='span'
          onClick={handleClickOpen}
          sx={{
            background: 'none !important',
            border: 'none',
            padding: '0 !important',
            color: '#069',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          {props.children}
        </Box>
      </>
    )
  }
}

export default ContactLink
