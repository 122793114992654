import * as React from 'react'

import {
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material'
export default function FormDialog ({
  message,
  title,
  okLabel,
  cancelLabel,
  onOk = () => { },
  onCancel = () => { }
}) {
  const [open, setOpen] = React.useState(true)

  const handleCancel = () => {
    setOpen(false)
    onCancel()
  }

  const handleOk = () => {
    setOpen(false)
    onOk()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleCancel} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'> {title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color='primary'>
            {cancelLabel}
          </Button>
          <Button onClick={handleOk} color='primary'>
            {okLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
