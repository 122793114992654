import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const LoadingIndicator = styled(Box)(({ theme }) => ({
  margin: '2em auto',
  position: 'relative',
  width: 64,
  height: 64,
  '& div': {
    animation: 'lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
    transformOrigin: '32px 32px',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 6,
      height: 6,
      borderRadius: '50%',
      background: 'grey',
      margin: '-3px 0 0 -3px',
    },
    '&:nth-of-type(1)': {
      animationDelay: '-0.036s',
      '&:after': {
        top: 50,
        left: 50,
      },
    },
    '&:nth-of-type(2)': {
      animationDelay: '-0.072s',
      '&:after': {
        top: 54,
        left: 45,
      },
    },
    '&:nth-of-type(3)': {
      animationDelay: '-0.108s',
      '&:after': {
        top: 57,
        left: 39,
      },
    },
    '&:nth-of-type(4)': {
      animationDelay: '-0.144s',
      '&:after': {
        top: 58,
        left: 32,
      },
    },
    '&:nth-of-type(5)': {
      animationDelay: '-0.18s',
      '&:after': {
        top: 57,
        left: 25,
      },
    },
    '&:nth-of-type(6)': {
      animationDelay: '-0.216s',
      '&:after': {
        top: 54,
        left: 19,
      },
    },
    '&:nth-of-type(7)': {
      animationDelay: '-0.252s',
      '&:after': {
        top: 50,
        left: 14,
      },
    },
    '&:nth-of-type(8)': {
      animationDelay: '-0.288s',
      '&:after': {
        top: 45,
        left: 10,
      },
    },
  },
  '@keyframes lds-roller': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

function Component() {
  return (
    <LoadingIndicator>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingIndicator>
  )
}

export default Component