import * as React from 'react'
import {
  Box,
  AppBar,
  Toolbar,
  Typography
} from '@mui/material'

export default function Component(props) {

  return (
    <>
      <AppBar
        color='primary'
        position='relative'
      >
        <Box sx={{ height: '7px' }} />
        <Toolbar>
          <Box
            component='img'
            src='https://s3.amazonaws.com/donate.resources/txst/BobcatLogoMaroon.png'
            alt='University Logo'
            sx={{
              height: 130
            }}
          />
          <Typography
            variant='h2'
            component='div'
            align='center'
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            Texas State Bobcat Club<br />
            RallyGive Pledge
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  )
}
