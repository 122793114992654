import React from 'react'
import Button from '@mui/material/Button'
import TwitterIcon from '@mui/icons-material/Twitter'

function TwitterShareButton({ url, message }) {
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(message)}`

    const handleShare = () => {
        window.open(shareUrl, '_blank', 'noopener,noreferrer')
    }

    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<TwitterIcon />}
            onClick={handleShare}
            sx={{
                width: '100%',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: '1',
                flexBasis: '50%',
                fontSize: '1.2em'
            }}
        >
            Share on Twitter
        </Button>
    )
}

export default TwitterShareButton