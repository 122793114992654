import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Box, ThemeProvider, CssBaseline } from '@mui/material'
import { useGlobal } from 'store'

import Header from './Header'
import FormFields from './FormFields'
import Confirmation from './Confirmation'
import ErrorHandler from 'components/ErrorHandler'
import ContactUs from 'components/ContactUs'

import SeoHeaders from 'components/Seo'
import Footer from 'components/Footer'

import theme from 'theme'

export default function Component(props) {
  const { page } = useParams()
  const [globalState, globalActions] = useGlobal()

  const { status } = globalState

  React.useEffect(() => {
    globalActions.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-braintree-token'
    })
  }, [globalActions])

  React.useEffect(() => {
    if (typeof page === 'string' && page.toLowerCase() === 'contact') {
      globalActions.setStatus('CONTACT')
    }
  }, [globalActions, page])

  const doit = () => {
    if (status === 'PAYMENT_SUCCESS') {
      return <Confirmation />
    }
    if (status === 'ERROR') {
      return <ErrorHandler />
    }
    if (status.substring(0, 7) === 'CONTACT') {
      return <ContactUs />
    }
    return <FormFields />
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SeoHeaders />
        <Box
          sx={{
            maxWidth: 1050,
            margin: 'auto'
          }}>
          <Header />
          {doit()}
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  )
}
