import * as React from 'react'
import {
  Box,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import TwitterShareButton from 'components/TwitterShareButton'
import FacebookShareButton from 'components/FacebookShareButton'
import RawHtml from 'components/RawHtml'
import { useGlobalState } from 'store'
import context from 'util/context'

export default function Component({ ...props }) {
  const globalState = useGlobalState()
  const { fb } = globalState
  const {
    form
  } = globalState.page


  return (
    <>
      <Paper sx={{
        padding: '12px'
      }}>
        <Grid container spacing={1} sx={{
          padding: '12px'
        }}>
          {fb.confirmation_header_text &&
            <Grid item xs={12}>
              <Box sx={{
                textAlign: 'center',
                backgroundColor: 'primary.main',
                color: 'white',
                padding: '16px'
              }}>
                <Typography variant='h4'>
                  {fb.confirmation_header_text}
                </Typography>
              </Box>
            </Grid>}
          {fb.confirmation_header_image &&
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  padding: '16px'
                }}>
                <Box
                  component='img'
                  src={fb.confirmation_header_image}
                  alt='Message Header'
                  sx={{ width: '100%' }}
                />
              </Box>
            </Grid>}
          <Grid item xs={12}>
            <Box sx={{
              padding: '16px'
            }}>
              <Grid container>
                {fb.confirmation_body_image &&
                  <Grid item xs={12} align='center'>
                    <Box
                      component='img'
                      alt='University Logo'
                      src={fb.confirmation_body_image}
                      sx={{
                        width: '100%',
                        maxWidth: 500,
                        marginBottom: '15px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}
                    />
                  </Grid>}
                {fb.confirmation_body_text &&
                  <Grid item xs={12} align='center'>
                    <RawHtml
                      content={fb.confirmation_body_text
                        .replace(/{share_url}/i, fb.share_url)
                        .replace(/{descriptor}/i, fb.descriptor)
                        .replace(/{first_name}/i, form.first_name)
                        .replace(/{last_name}/i, form.last_name)
                        .replace(/{email_address}/i, form.email_address)
                        .replace(/{amount}/i, context.formatMoney(form.total_charge_amount))}
                    />
                  </Grid>}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{
              margin: '1px',
              '& a': {
                width: '100%',
                margin: '0'
              }
            }}>
              <FacebookShareButton
                url={fb.share_url}
                message={fb.share_message}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{
              margin: '1px',
              '& a': {
                width: '100%',
                margin: '0'
              }
            }}>
              <TwitterShareButton
                url={fb.share_url}
                message={fb.share_message}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper >
    </>
  )
}
