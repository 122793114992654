import * as React from 'react'
import { createRoot } from 'react-dom/client'
import animateScrollTo from 'animated-scroll-to'
import awsconfig from 'aws-exports'
import GetInput from 'components/modals/GetInput'
import OkCancel from 'components/modals/OkCancel'
import Alert from 'components/modals/Alert'
import deepmerge from 'deepmerge'

import {
  Typography
} from '@mui/material'

export const context = {
  gradient: 'linear-gradient(90deg,  rgba(0,103,128,1)  15%, rgba(0,149,175,1) 36%)',
  gradientVert: 'linear-gradient(180deg,  rgba(0,103,128,1)  15%, rgba(0,149,175,1) 36%)',
  merge: deepmerge,
  environment: awsconfig.aws_cloud_logic_custom[0].endpoint.split('/').pop(),
  getTimeRemaining: (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    }
  },
  formatMoney: (amount, places = 2) => Number(amount).toFixed(places).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  round: (amount) => Number(amount).toFixed(2),
  getOffset: (el) => {
    let _x = 0
    let _y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft
      _y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return { top: _y, left: _x }
  },
  scrollToEl: (el) => {
    console.log(el)
    console.log(context.getOffset(el).top)
    window.scrollTo(0, context.getOffset(el).top)
  },
  scrollToRef: (ref) => window.scrollTo(0, ref.current.offsetTop),
  animateScrollTo: (selector, options) => {
    animateScrollTo(document.querySelector(selector), options)
  },
  animateScrollToName: (selector, options) => {
    if (document.querySelector(`label[for=${selector}]`)) {
      animateScrollTo(document.querySelector(`label[for=${selector}]`), options)
    } else if (document.querySelector(`input[name=${selector}]`)) {
      animateScrollTo(document.querySelector(`input[name=${selector}]`), options)
    } else if (document.querySelector(`input[id=${selector}]`)) {
      animateScrollTo(document.querySelector(`input[id=${selector}]`), options)
    }
  },
  calc_fee: (amount) => {
    return amount * 0.029 + 0.3
  },
  host: () => {
    if (window.location.hostname === 'localhost') {
      return 'www.caringcent.org'
    } else {
      return window.location.hostname
    }
  },
  url: () => {
    if (window.location.hostname === 'localhost') {
      return 'https://www.caringcent.org' + window.location.pathname
    } else {
      return 'https://' + window.location.hostname + window.location.pathname
    }
  },
  checklocals: (locals) => {
    const errors = []
    const keys = [
      'campaign',
      'descriptor'
    ]
    keys.forEach(item => {
      if (!locals[item]) { errors.push(`${locals.title} is missing config.${item}`) }
    })
    if (locals.descriptor.length > 22) { errors.push('Descriptor is over 22 characters') }
    if (errors.length) alert(errors.join('\n'))
  },
  shallowEqual: (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      return false
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false
      }
    }
    return true
  },
  deepEqual: deepEqual,
  isObject: (object) => {
    return object != null && typeof object === 'object'
  },
  isFunction: (value) => {
    return typeof value === 'function'
  },
  isArray: (object) => {
    return object != null && Array.isArray(object)
  },
  isString: (object) => {
    return typeof object === 'string' || object instanceof String
  },
  isEmpty: (obj) => {
    if (context.isArray(obj)) return obj.length === 0
    for (const i in obj) return false
    return true
  },
  isMetricEmpty: (metrics) => {
    for (const metric of Object.values(metrics)) {
      if (metric.selected === true) {
        return false
      }
    }
    return true
  },
  humanFileSize: (bytes, si = true, dp = 1) => {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
  },
  copy: (obj) => {
    return recursiveAssign({}, obj)
  },
  jsonCopy: (obj) => {
    return JSON.parse(JSON.stringify(obj))
  },
  getQuery: () => {
    return new URLSearchParams(window.location.search)
  },
  getParams: () => {
    const query = new URLSearchParams(window.location.search)
    const data = {}
    for (const [key, value] of query) {
      data[key] = value
    }
    return data
  },
  useWindowDimensions: () => {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
  },
  getResultPopup: (message, title, inputLabel = '', defaultValue = '') => {
    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }

      const PopupContent = () => {
        return (
          <GetInput
            message={message}
            title={title}
            label={inputLabel}
            defaultValue={defaultValue}
            cancelLabel='Cancel'
            saveLabel='Save'
            onSave={(result) => { finalize(); resolve(result) }}
            onCancel={(result) => { finalize(); reject(result) }}
          />
        )
      }
      createRoot(node).render(PopupContent())
    })
  },
  okCancelPopup: (message, title) => {

    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }

      const PopupContent = () => {
        return (
          <OkCancel
            message={message}
            title={title}
            okLabel='Ok'
            cancelLabel='Cancel'
            onOk={() => { finalize(); resolve('ok') }}
            onCancel={() => { finalize(); reject('cancel') }}
          />
        )
      }
      createRoot(node).render(PopupContent())
    })
  },
  alertPopup: (message, title) => {
    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }
      const PopupContent = () => {
        return (
          <Alert
            message={message}
            title={title}
            buttonLabel='Ok'
            onCancel={() => { finalize(); resolve() }}
          />
        )
      }

      createRoot(node).render(PopupContent())
    })
  },
  handleApiError: (error) => {
    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }

      const PopupContent = () => {
        return (
          <Alert
            message={<>
              <Typography sx={{
                fontSize: 12,
                pt: 2,
                color: 'red'
              }}>
                {error.message}
              </Typography>
            </>}
            title='Unhandled API Gateway Error'
            buttonLabel='Ok'
            onCancel={() => { finalize(); resolve() }}
          />
        )
      }
      createRoot(node).render(PopupContent())
    })
  },
  handlePythonException: (exc) => {
    return new Promise((resolve, reject) => {
      const node = document.createElement('div')
      document.body.appendChild(node)

      const finalize = () => {
        node.remove()
      }

      const PopupContent = () => {
        if (context.environment !== 'production') {
          return (
            <Alert
              message={<>
                <Typography sx={{
                  fontSize: 12,
                  pt: 2,
                  color: 'red'
                }}>
                  {exc.value}
                </Typography>

                <Typography sx={{
                  fontSize: 12,
                  pt: 2
                }}>
                  {exc.type}
                </Typography>

                <Typography sx={{
                  fontSize: 12,
                  pt: 2
                }}>
                  <pre>
                    {exc.traceback}
                  </pre>
                </Typography>
              </>}
              title='Python Exception (Not visible in production)'
              buttonLabel='Ok'
              onCancel={() => { finalize(); resolve() }}
            />
          )
        } else {
          // create a user friendy error message from an unhandled python exception
          return (
            <Alert
              message={<>
                <Typography sx={{
                  fontSize: 12,
                  pt: 2
                }}>
                  Oops, looks like something went wrong!
                  Sometimes unexpected errors occur.
                  Please try again later, or contact customer
                  support using the contact us link and
                  mention the error below in red.
                </Typography>

                <Typography sx={{
                  fontSize: 12,
                  pt: 2,
                  color: 'red'
                }}>
                  {exc.value}
                </Typography>
              </>}
              title='Oops, an error occurred.'
              buttonLabel='Ok'
              onCancel={() => { finalize(); resolve() }}
            />
          )
        }
      }

      createRoot(node).render(PopupContent())
    })
  },

  useInterval: (callback, delay, callInitial = true) => {
    const savedCallback = React.useRef()

    // Remember the latest callback.
    React.useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    React.useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])

    // Call initial.
    React.useEffect(() => {
      if (callInitial) {
        callback()
      }
    }, [callInitial, callback])
  },
  redirect: (redirect) => {
    if (redirect.includes('http') && redirect.includes('//')) {
      window.location.replace(redirect)
    } else if (window.location.hostname === 'localhost') {
      window.location.replace('http://' + window.location.hostname + ':' + window.location.port + redirect)
    } else {
      window.location.replace('https://' + window.location.hostname + redirect)
    }
  },
  imageUrl: (path, wrap = false, base = 'https://s3.amazonaws.com/donate.resources') => {
    return `${wrap ? 'url(' : ''}${base}${path}${wrap ? ')' : ''}`
  },
  invertList: (list, key) => {
    const result = {}
    for (const i of list) {
      result[i[key]] = result[i[key]] || []
      result[i[key]].push(i)
    }
    return result
  },
  to_json: (data) => {
    return JSON.stringify(data)
  },
  formatDate: (string) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(string).toLocaleDateString([], options)
  }
}


function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = context.isObject(val1) && context.isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}
function recursiveAssign(a, b) {
  if (context.isFunction(b)) return b
  if (Object(b) !== b) return b
  // Replace Arrays, do not merge
  if (context.isArray(b)) {
    a = []
    for (let i = 0; i < b.length; i++) {
      a.push(recursiveAssign(a[i], b[i]))
    }
    return a
  }
  // Merge objects, do not replace
  if (Object(a) !== a) a = {}
  for (let key in b) {
    a[key] = recursiveAssign(a[key], b[key])
  }
  return a
}
context.recursiveAssign = recursiveAssign

export default context
