import * as React from 'react'
import {
  Box,
} from '@mui/material'
import TermsOfService from 'apps/legal/termsofservice'

export default function Content() {

  return (
    <Box
      align="justify"
      sx={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}
    >
      <Box sx={{ margin: 2 }}>
        CaringCent ("<b>CaringCent</b>,” "<b>us</b>,” "<b>we</b>,” or "<b>our</b>”)
        is committed to protecting your privacy.  CaringCent has prepared this Privacy Policy
        (this "<b>Privacy Policy</b>”)
        to describe to you, or if you represent an entity or other
        organization, that entity or organization, (in either case, "<b>You</b>”)
        our practices regarding the personal information that CaringCent
        collects, uses, and shares on behalf of end users ("<b>Users</b>”)
        of our online platform for making and receiving donations
        ("<b>Platform</b>”).
        Capitalized terms not defined in this Privacy Policy, shall have the
        meetings as defined in the <TermsOfService variant='link'>Terms Of Service</TermsOfService>.
      </Box>

      <Box sx={{ margin: 2 }}>
        CaringCent
        reserves the right, at any time, to modify this Privacy Policy.  If
        we make revisions that change the way we collect, use, or share
        personal information, we will post those changes in this Privacy
        Policy.  You should review this Privacy Policy periodically so that
        You keep up to date on our most current policies and practices.  We
        will note the effective date of the latest version of our Privacy
        Policy at the end of this Privacy Policy.
      </Box>

      <ol>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            Collection of Personal Information.
          </Box>
          <Box sx={{ margin: 1 }}>
            Personal information refers to information that is connected to
            Your name and may include things such as Your email address, mailing
            address, phone number and date of birth.
          </Box>
          <Box sx={{ margin: 1 }}>
            CaringCent
            collects personal information when Users voluntarily submit it to us.
            For example, we may collect or receive information from You when You
            create a User Account, edit Your Registration Information, make a
            donation through the Platform, or use or access a Service or Content
            available through the Platform.  In addition to personal information
            that we may receive from You, CaringCent may collect other
            information from Users using the following technologies:
          </Box>
          <Box sx={{ margin: 1 }}>
            <ul>
              <li>
                <Box><u>Cookies</u>.
                  "Cookies" are small bits of information that the Platform
                  places on the hard drive of Your computer.  Cookies remember
                  information about Your activities on the Platform to make Your
                  visits to the Platform more enjoyable and valuable to You by
                  providing a customized experience and recognizing Your preferences
                  when You visit the Platform.  If You've chosen to disable cookies on
                  Your browser, some of the functionality of the Platform may be lost.
                  Certain Services provided by CaringCent, require that cookies be
                  enabled in Your browser and cannot be used when You have disabled
                  cookies in Your browser.
                </Box>
              </li>
              <li>
                <Box><u>Flash Cookies</u>.
                  (also called Local Shared Objects or "LSOs”).  Flash
                  cookies are data files similar to cookies, except that they can
                  store more complex data.  Flash cookies are used to remember
                  settings, preferences, and usage, particularly for video,
                  interactive gaming, and other similar services.
                </Box>
              </li>
              <li>
                <Box><u>Web beacons or clear pixels</u>.
                  Web beacons are small graphic images on a web page or in an email
                  that can be used for such things as recording the pages and
                  advertisements that You select, or tracking the performance of email
                  marketing campaigns.
                </Box>
              </li>
              <li>
                <Box><u>Web server logs</u>.
                  Web server logs are records of activity created by the computer
                  that delivers the webpages You request to Your browser.  For
                  example, a web server log may record the search term You entered or
                  the link You clicked to bring You to the webpage.  The web server
                  log also may record information about Your browser, such as Your IP
                  address and the cookies set on Your browser by the server.
                </Box>
              </li>
            </ul>
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            USE OF PERSONAL INFORMATION.
          </Box>
          <Box sx={{ margin: 1 }}>
            CaringCent's primary purpose in collecting personal
            information is to facilitate the Services that you select on the
            Platform.  CaringCent may also use personal information for various
            purposes, including without limitation to:
          </Box>
          <Box sx={{ margin: 1 }}>
            <ul>
              <li>
                <Box>
                  To help diagnose problems with our Platform, to administer our
                  Platform, and to enhance the Platform for optimal User experience.
                </Box>
              </li>
              <li>
                <Box>
                  To monitor the usage and performance of our Platform, and to determine
                  aggregate information about our Users.</Box>
              </li>
              <li>
                <Box>
                  To facilitate the processing of transactions between Users and to send
                  receipts.
                </Box>
              </li>
              <li>
                <Box>
                  To provide maintenance, support, and customer service for the Platform.
                </Box>
              </li>
              <li>
                <Box>
                  To contact Users for information verification purposes and to
                  administer User Accounts.
                </Box>
              </li>
            </ul>
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase' }}>
            <b>Sharing of Personal Information.</b>
          </Box>
          <Box sx={{ margin: 1 }}>
            Any Organization you make a contribution to through the Platform will
            have access to Your personal information. Organizations will not have
            any access to payment card information, rounding account personal
            information, passwords, and credit card purchase data.  You consent
            to the foregoing and agree that we are not responsible for how these
            Organizations handle Your personal information.  You should contact
            them directly for their terms and privacy policies.
          </Box>
          <Box sx={{ margin: 1 }}>
            CaringCent may share Your personal information with service providers that
            perform services for CaringCent that are necessary for the orderly
            operation of the Platform, such as providing marketing assistance,
            analyzing User Content, and processing payment card information.
            CaringCent contracts with service providers, such as payment
            processing providers and rounding solution providers, to process all
            donations that Users make through the Platform.  Personal information
            handled by these service providers is subject to their terms and
            privacy policies. <b>
              Please review the service provider's terms and privacy policies to
              better understand how they manage your data. Among the various
              service providers that CaringCent utilizes, CaringCent works with
              Plaid to provide various Services to You.  By accessing or using the
              Platform, Content, or Services, You expressly agree to and grant
              Plaid the right to use Your personal information in accordance with
              the </b>  <Box
                component='a'
                href="https://www.plaid.com/legal/#Privacy-Policy"
                target='_blank'>
              Plaid Privacy Policy
            </Box>.
          </Box>
          <Box sx={{ margin: 1 }}>
            There may also be instances when CaringCent may disclose Your personal
            information without providing You with a choice in order to protect
            the legal rights of CaringCent, other affiliated companies or their
            employees, agents, and contractors; to honor a request that You have
            made through the Platform; to protect the safety and security of
            other Users; to protect against fraud or for risk management
            purposes; or to comply with the Law or legal process (e.g., to
            protect You and other Users against identity theft).  In addition, if
            CaringCent sells all or part of its business or makes a sale or
            transfer of assets or is otherwise involved in a merger or business
            transfer, CaringCent may transfer Your Personal Information to a
            third party as part of that transaction.
          </Box>
          <Box sx={{ margin: 1 }}>
            The Platform may offer You the ability to share Your personal information
            through a social networking site (e.g., Facebook, Twitter), using
            such site's integrated tools (e.g., Facebook "Like”
            button, or Twitter "Tweet” button).  The use of such
            integrated tools enables You to share personal information about
            Yourself with other individuals or the public, depending on the
            settings that You have established with such social networking site.
            For more information about the purpose and scope of data collection
            and use in connection with such social networking site or a site's
            integrated tools, please visit the privacy policies of the entities
            that provide these social networking sites.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            Security.
          </Box>
          <Box sx={{ margin: 1 }}>
            CaringCent employs reasonable security measures to protect personal
            information from becoming disclosed to individuals who are not
            described in this Privacy Policy.  While there is no such thing as
            "perfect security” on the Internet, we will take
            reasonable steps to ensure the safety of Your personal information.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            NOTICE TO CALIFORNIA RESIDENTS.
          </Box>
          <Box sx={{ margin: 1 }}>
            Under California Civil Code sections 1798.83-1798.84, California
            residents who have an established business relationship with
            CaringCent are entitled to ask us for a notice describing what
            categories of personal customer information we share with third
            parties for their direct marketing purposes.  This notice will
            identify the categories of information shared with and will include
            a list of the third parties with which it is shared, along with
            their names and addresses.  If You are a California resident and
            would like a copy of this notice, please submit a written request to
            us at the addresses listed below.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Communications.</Box>
          <Box sx={{ margin: 1 }}>
            If You have any additional questions or concerns about our Privacy
            Policy or any other privacy or security issue, please contact us at:
          </Box>
          <Box sx={{
            marginLeft: '0.5in',
            marginBottom: '0in',
            fontSize: '10.0pt',
            fontFamily: '"Arial",sans-serif',
            color: 'black'
          }}>
            <Box>CaringCent</Box>
            <Box>Attn: CaringCent Copyright Agent</Box>
            <Box>5308 Cortaderia PL NE</Box>
            <Box>Albuquerque, NM&nbsp; 87111</Box>
            <Box
              component='a'
              href="mailto:info@caringcent.com">
              info@caringcent.com
            </Box>
            <Box>&nbsp;</Box>
          </Box>

          <Box>
            If CaringCent needs, or is required, to contact You concerning any event
            that involves information about You, we may do so by email, telephone, or mail.
          </Box>
        </li>
      </ol>

    </Box >
  )
}