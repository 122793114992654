import * as React from 'react'
import { useGlobalState } from 'store'
import {
  Box,
  Grid,
  Typography
} from '@mui/material'

export const Component = (props) => {
  const globalState = useGlobalState()

  const {
    status,
    error,
    message,
    traceback
  } = globalState

  return (
    <>
      <Box sx={{ marginLeft: 150 }}>
        <Grid item xs={12}>
          <Typography variant='h2'>{status}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h3'>{error}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h4'>{message}</Typography>
        </Grid>
        {
          process.env.NODE_ENV !== 'production' &&
          <Grid item xs={12}>
            {traceback}
          </Grid>
        }
      </Box>
    </>
  )
}

export default Component
