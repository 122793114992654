import * as React from 'react'

import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography
} from '@mui/material'
import { useGlobal } from 'store'

import Braintree from 'components/Braintree'
import CreateAccount from './CreateAccount'
import Expando from 'components/Expando'
import PlaidLink from './PlaidLink'

function getSteps() {
  return [
    'Create an account',
    'Choose a way to donate',
    'Select an account to roundup'
  ]
}

function getStepContent(step, org_name) {
  switch (step) {
    case 0:
      return <CreateAccount />
    case 1:
      return (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h5'>
                We vault your card with Braintree and charge your donation to your credit card once a month.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Expando
                above={
                  <>
                    <Typography variant='body1'>
                      <ul>
                        <li>We use braintree</li>
                        <li>We protect your data</li>
                        <li>We charge $5 minimum if you don't reach $5 in rounding</li>
                      </ul>
                    </Typography>
                  </>
                }
              >
                <Typography variant='body1'>
                  Blah blah blah
                </Typography>
              </Expando>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Braintree />
            </Grid>
          </Grid>
        </>
      )
    case 2:
      return (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h5'>
                Each transaction is rounded up to the nearest dollar and donated to {org_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Expando
                above={
                  <>
                    <Typography variant='body1'>
                      <ul>
                        <li>We use plaid to link to your account</li>
                        <li>We protect your data </li>
                        <li>Send you monthly reports</li>
                      </ul>
                    </Typography>
                  </>
                }
              >
                <Typography variant='body1'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam venenatis facilisis erat vitae cursus. Nullam euismod nulla vitae mollis tristique. Suspendisse ac libero commodo, egestas justo at, hendrerit justo. Aliquam velit orci, viverra pretium malesuada in, gravida in ex. Donec porta nulla id tortor finibus placerat. Sed iaculis nisl sed ullamcorper interdum. In eget libero at lorem pellentesque porta at vel mauris. Morbi euismod imperdiet mauris.
                </Typography>
              </Expando>
            </Grid>
            <Grid item xs={12} sm={6}>
              <PlaidLink />
            </Grid>
          </Grid>
        </>
      )
    default:
      return 'Unknown step'
  }
}

export default function Component() {
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()
  const [globalState, globalActions] = useGlobal()


  const {
    org_name
  } = globalState.page.config

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Box
      sx={{
        width: '100%'
      }}>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index, org_name)}</Typography>
              <Box sx={{
                marginBottom: '16px'
              }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    marginTop: '8px',
                    marginRight: '8px'
                  }}
                >
                  Back
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleNext}
                  sx={{
                    marginTop: '8px',
                    marginRight: '8px'
                  }}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <>
          <Paper
            square
            elevation={0}
            sx={{
              padding: '21px'
            }}>
            <Typography>All steps completed - you&apos;re all signed up to make an impact on {org_name}.</Typography>
          </Paper>
          <Button
            variant='contained'
            color='primary'
            onClick={globalActions.createPlaceHolder('Stepper:onClick')
            }>
            Take me to my dashboard
          </Button>
        </>
      )}
    </Box>
  )
}
