const fontSize = 14

const object = {
  fontFamily: [
    'Poppins',
    'Roboto',
    'sans-serif'
  ].join(','),
  h1: {
    fontWeight: 500,
    fontSize: fontSize + 12,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: fontSize + 10,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 400,
    fontSize: fontSize + 8,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 400,
    fontSize: fontSize + 6,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 400,
    fontSize: fontSize + 4,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 400,
    fontSize: fontSize + 2,
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  },
  fontSize: fontSize,
  body2: {
    fontSize: fontSize
  },
  body1: {
    fontSize: fontSize
  }
}

export default object
