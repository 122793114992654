import * as React from 'react'
import context from 'util/context'
import { useGlobal } from 'store'
import DropDown from 'components/DropDown'
import Metric from './Metric'
import Donation from './Donation'
import PledgeCap from 'components/PledgeCap'
import PersonalInformation from './PersonalInformation'
import SpotDonation from './SpotDonation'
import Recaptcha from 'components/Recaptcha'
import Tipping from './Tipping'
import TermsOfService from 'apps/legal/termsofservice/Button'
import Braintree from 'components/Braintree'
import RawHtml from 'components/RawHtml'
import * as Yup from 'yup'

import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  FormLabel,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material'

const pixel = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg=='

export const FormFields = () => {
  // handle is a reference to the braintree object which we need to make reference to when submitting a
  // transaction and in case of credit card processing error.
  const [handle, setHandle] = React.useState()

  const [globalState, globalActions] = useGlobal()

  const {
    braintree_client_token,
    fb
  } = globalState
  console.log(globalState)
  const {
    form,
    errors,
    config,
    donations
  } = globalState.page

  function onChange(event) {
    globalActions.setForm({
      formName: 'page',
      action: 'setConfig',
      name: 'is_processing',
      value: false
    })
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    globalActions.setForm({
      formName: 'page',
      action: 'set',
      name: name,
      value: value
    })
  }

  const validate = {
    email_address: Yup.string()
      .email('Invalid email')
      .required('Required'),
    recaptcha_token: Yup.string()
      .required('Please check the box below')
  }

  if (fb.pii_collect_first_name) {
    validate.first_name = Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  }

  if (fb.pii_collect_last_name) {
    validate.last_name = Yup.string()
      .min(1, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  }

  if (fb.pii_collect_postal_address) {
    validate.street_address = Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  }

  if (fb.pii_collect_postal_zipcode) {
    validate.postal_code = Yup.string()
      .min(5, 'Too Short!')
      .max(10, 'Too Long!')
      .required('Required')
  }
  console.log('donations', donations)
  Array.from({ length: 9 }, (_, i) => i + 1).map(key => {
    const donation = donations[key]
    if (donation && donation.selected) {
      if (fb[`donation_${key}_dropdown_label`] && !donation.dropdownOption) {
        validate[`donation_${key}_dropdown`] = Yup.string()
          .required('Required')
      }
    }
    return null
  })

  if (config.acronym === 'OSU' || fb.special_fund_dropdown) {
    validate.special_fund = Yup.string()
      .required('Please select where you want your contribution to be applied')
  }

  const schema = Yup.object().shape(validate)

  function onSubmit(event) {
    globalActions.submitDonateForm(handle, schema)
  }

  return (
    <>
      <Paper
        sx={{
          padding: '12px',
          marginTop: '40px',
          marginLeft: '40px',
          marginRight: '40px'
        }}
      >
        {braintree_client_token &&
          <>
            <Grid container spacing={3} sx={{
              padding: '12px'
            }}>

              <Grid item xs={12} />
              {fb.form_instructions &&
                <Grid item xs={12}>
                  <RawHtml content={fb.form_instructions} />
                </Grid>}

              <span error-id='donation_error' />
              {errors.donation_error &&
                <Grid item xs={12}>
                  <Alert severity='error'>
                    {errors.donation_error}
                  </Alert>
                </Grid>}

              {Array.from({ length: 9 }, (_, i) => i + 1).map(key => {
                if (fb[`donation_${key}_label`]) {
                  return (
                    <Grid item key={`Donation-${key}`}>
                      <Grid container spacing={3}>
                        {fb[`donation_${key}_header`] &&
                          (<Grid item xs={12}>
                            <FormLabel component='legend' sx={{
                              color: 'black !important',
                              fontWeight: 'bold !important',
                            }}>
                              {fb[`donation_${key}_header`]}
                            </FormLabel>
                          </Grid>
                          )}

                        {fb[`donation_${key}_html`] &&
                          (<Grid item xs={12}>
                            <RawHtml content={fb[`donation_${key}_html`]} />
                          </Grid>
                          )}

                        {fb[`donation_${key}_image`] && (<>
                          <Grid item xs={12} sm={3}>
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                            >
                              <Box
                                component='img'
                                alt={key}
                                src={fb[`donation_${key}_image`] || pixel}
                                sx={{ width: '100%' }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Donation
                              formName='page'
                              donationName={key}
                              label={fb[`donation_${key}_label`]}
                              secondaryText={fb[`donation_${key}_secondarytext`]}
                              helperText={fb[`donation_${key}_helpertext`]}
                              defaultValue={fb[`donation_${key}_defaultvalue`]}
                              values={fb[`donation_${key}_values`]}
                              showOther={fb[`donation_${key}_show_other`]}
                              orientation={fb[`donation_${key}_orientation`]}
                              dropdownOptions={fb[`donation_${key}_dropdown`]}
                              dropdownLabel={fb[`donation_${key}_dropdown_label`]}
                            />
                          </Grid>
                        </>
                        )}
                        {!fb[`donation_${key}_image`] && (
                          <Grid item xs={12}>
                            <Donation
                              formName='page'
                              donationName={key}
                              label={fb[`donation_${key}_label`]}
                              secondaryText={fb[`donation_${key}_secondarytext`]}
                              helperText={fb[`donation_${key}_helpertext`]}
                              defaultValue={fb[`donation_${key}_defaultvalue`]}
                              values={fb[`donation_${key}_values`]}
                              showOther={fb[`donation_${key}_show_other`]}
                              orientation={fb[`donation_${key}_orientation`]}
                              dropdownOptions={fb[`donation_${key}_dropdown`]}
                              dropdownLabel={fb[`donation_${key}_dropdown_label`]}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )
                }
                return null
              })}

              <span error-id='metric_error' />
              {errors.metric_error &&
                <Grid item xs={12}>
                  <Alert severity='error'>
                    {errors.metric_error}
                  </Alert>
                </Grid>}

              {fb.spot_option_show &&
                !fb.spot_option_bottom &&
                <Grid item xs={12}>
                  <SpotDonation formName='page' />
                </Grid>}

              {fb.metric_section_instructions &&
                <>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <RawHtml content={fb.metric_section_instructions} />
                  </Grid>
                </>}

              <Grid item xs={12} />
              {Object.entries(fb.metrics).map(([key, item]) => {
                if (!item.options || !item.options.label) {
                  return null
                }

                return (
                  <Grid item key={`Metric-${key}`}>
                    <Grid container spacing={3}>
                      {item.header &&
                        (<Grid item xs={12}>
                          <FormLabel component='legend' sx={{
                            color: 'black !important',
                            fontWeight: 'bold !important',
                          }}>
                            {item.header}
                          </FormLabel>
                        </Grid>
                        )}

                      {item.image && (<>
                        <Grid item xs={12} sm={3}>
                          <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Box
                              component='img'
                              alt={key}
                              src={item.image || pixel}
                              sx={{ width: '100%' }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Metric
                            formName='page'
                            metricName={key}
                            {...item.options}
                          />
                        </Grid>
                      </>
                      )}
                      {!item.image && (
                        <Grid item xs={12}>
                          <Metric
                            formName='page'
                            metricName={key}
                            {...item.options}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )
              })}

              {fb.show_pledge_cap &&
                <>
                  <Grid item xs={12} />
                  <PledgeCap />
                </>}

              {fb.show_rally_whole_season && <>
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <FormLabel component='legend' sx={{
                    color: 'black !important',
                    fontWeight: 'bold !important',
                  }}>
                    Do you want your pledge donations to apply to the team's success from the start of the season?
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='rally_whole_Season'
                        name='rally_whole_season'
                        checked={form.rally_whole_season}
                        onChange={onChange}
                        color='primary'
                        error={Boolean(errors.rally_whole_season)}
                      />
                    }
                    label='Yes, I would like to donate for the whole season, including past games!'
                  />
                  <Box sx={{
                    fontSize: '.7em'
                  }}
                  >
                    (Leave unchecked to support only games from today till end of season)
                  </Box>
                  <span error-id='rally_whole_Season' />
                  {errors.rally_whole_Season &&
                    <Alert severity='error'>
                      {errors.rally_whole_Season}
                    </Alert>}
                </Grid>
              </>}

              {fb.spot_option_show &&
                fb.spot_option_bottom && <>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <SpotDonation formName='page' />
                  </Grid>
                </>}

              {fb.tipping_enabled && <Tipping formName='page' />}

              {config.acronym === 'OSU' &&
                <>
                  <Grid item xs={12} />
                  <Grid item xs={12} sm={6}>
                    <DropDown
                      name='special_fund'
                      required
                      values={{
                        AcceptBeaverPoints: 'I accept Beaver Points resulting in no tax benefits',
                        DeclineBeaverPoints: 'I decline Beaver Points providing potential tax benefits'
                      }}
                      label='Would you like to accept or decline priority points for your gift?'
                    />
                  </Grid>
                </>}

              <Grid item xs={12} />
              <Grid item xs={12}>
                <span error-id='total_charge_amount' />
                {errors.total_charge_amount &&
                  <Alert severity='error'>
                    {errors.total_charge_amount}
                  </Alert>}
                <Typography
                  variant='body1'
                  paragraph
                  sx={{
                    color: 'black !important',
                    fontWeight: 'bold !important',
                  }}
                >
                  Total Donation Today : ${context.round(form.total_charge_amount)}
                </Typography>
              </Grid>
              <PersonalInformation />
              <Grid item xs={12}>
                <Braintree handle={handle} setHandle={setHandle} />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{
              padding: '12px'
            }}>
              <Recaptcha />
            </Grid>
            <Grid item xs={12} sx={{
              padding: '12px'
            }}>
              <Typography
                variant='body1'
                paragraph
              >
                Donations are processed through our partner and will show up on
                credit card statements as "CaringCent" or "{fb.descriptor}".
              </Typography>

              By clicking the Submit button, you agree to our{' '}
              <TermsOfService variant='link'>
                Terms of Service
              </TermsOfService>
            </Grid>

            <Grid item xs={12} sx={{
              padding: '12px'
            }}>
              {braintree_client_token &&
                <Button variant='contained' color='primary' onClick={onSubmit} disabled={config.is_processing}>
                  {config.is_processing ? 'Processing...' : 'Submit'}
                </Button>}
            </Grid>
          </>} {/* End braintree_client_token */}
      </Paper>
    </>
  )
}

export default FormFields
