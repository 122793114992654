import * as React from 'react'
import Banner from 'apps/clientlist/Logos/CCAthletics.webp'
import { Box, Grid, Paper } from '@mui/material'

const Header = () => {
  return (
    <Grid
      container
      spacing={6}
      sx={{
        marginBottom: '1em'
      }}>
      <Grid item xs={12}>
        <Paper>
          <Box
            sx={{
              padding: '15px',
              margin: 'auto',
              textAlign: 'center',
              position: 'relative',
            }}>
            <Box
              component='img'
              src={Banner}
              alt='CaringCent Athletics'
              sx={{
                width: '50%',
              }}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Header
