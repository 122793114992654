import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import { useGlobalState } from 'store'

export default function SeoHeaders() {
  const globalState = useGlobalState()
  const { fb } = globalState

  const desc = 'Help build a season to remember for all our athletes!'

  return (
    <>
      <Helmet
        title={fb.page_title}
        meta={[
          { name: 'description', property: 'og:description', content: desc },
          { property: 'og:title', content: fb.page_title },
          { property: 'og:url', content: fb.share_url },
          { property: 'og:image', content: fb.secure_image_url },
          { property: 'og:image:type', content: fb.secure_image_type },
          { property: 'twitter:image:src', content: fb.secure_image_url },
          { property: 'twitter:title', content: fb.page_title },
          { property: 'twitter:description', content: desc }
        ]}
      />
    </>
  )
}
