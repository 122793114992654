import * as React from 'react'
import { useGlobalActions } from 'store'
import {
  Box,
  Button,
} from '@mui/material'

export default function ScrollDialog(props) {
  const { globalActions } = useGlobalActions()

  const handleClickOpen = () => {
    globalActions.mergeState({
      toggles: {
        ShowTermsOfService: true,
        ShowPrivacyPolicy: false,
      }
    })
  }

  if (props.variant && props.variant.toLowerCase() === 'link') {
    return (
      <>
        <Box
          component='span'
          onClick={handleClickOpen}
          sx={{
            background: 'none !important',
            border: 'none',
            padding: '0 !important',
            color: '#069',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          {props.children}
        </Box>
      </>
    )
  } else {
    return (
      <>
        <Button onClick={handleClickOpen}>
          {props.children}
        </Button>
      </>
    )
  }
}
