import * as React from 'react'
import {
  Box,
} from '@mui/material'
import PrivacyPolicy from 'apps/legal/privacypolicy'

export default function Content() {

  return (
    <Box
      align="justify"
      sx={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}
    >
      <Box sx={{ margin: 2 }}>
        This CaringCent Terms of Service (this "<b>Agreement</b>")
        is agreed to between CaringCent ("<b>CaringCent</b>") and you, or, if
        you represent an entity or other organization, that entity or organization (in
        either case "<b>You</b>").&nbsp; CaringCent offers an online platform for
        making and receiving donations (the “<b>Platform</b>”), which may be accessed
        through the website located at www.caringcent.com, or any other website
        operated by CaringCent (the "<b>Site</b>"). The Platform provides end
        users seeking to make a donation through a rounding solution (“<b>Donors</b>”) with
        the ability to access information, data, and other content (“<b>Content</b>”);
        to identify charities and organizations (“<b>Organizations</b>,” collectively
        with Donors, “<b>Users</b>”); and to transact with and make donations to
        Organizations through the Platform (collectively, the “<b>Services</b>”).
      </Box>
      <Box sx={{ margin: 2, color: 'black' }}>
        PLEASE CAREFULLY READ THIS AGREEMENT. BY
        ACCESSING OR USING THE PLATFORM, CONTENT, OR SERVICES, OR BY CLICKING A BOX
        THAT STATES THAT YOU ACCEPT OR AGREE TO THIS AGREEMENT, YOU AGREE THAT YOU HAVE
        READ AND AGREE TO BE BOUND BY THIS AGREEMENT.&nbsp; IF YOU DO NOT AGREE TO THIS AGREEMENT,
        OR DO NOT MEET THE QUALIFICATIONS INCLUDED IN THIS AGREEMENT, CARINGCENT IS NOT
        WILLING TO PROVIDE YOU WITH ACCESS TO OR USE OF THE PLATFORM, CONTENT, OR
        SERVICES AND YOU MUST NOT ACCESS OR USE THE PLATFORM, CONTENT, OR SERVICES.&nbsp; IF
        YOU ACCESS OR USE THE PLATFORM, CONTENT, OR SERVICES, YOU ACKNOWLEDGE THAT YOU
        MEET THE QUALIFICATIONS INCLUDED IN THIS AGREEMENT AND AGREE TO BE BOUND BY
        THIS AGREEMENT.
      </Box>
      <Box sx={{ margin: 2 }}>
        This Agreement includes the terms and
        conditions below and the current <PrivacyPolicy variant='link'>Privacy Policy
        </PrivacyPolicy>  (the “<b>Privacy Policy</b>”) relating to the Platform.&nbsp;
        You are responsible for compliance with this Agreement (including this policy).
      </Box>
      <Box sx={{ margin: 2 }}>
        Unless You later enter into any other
        agreements with CaringCent regarding the Platform, Content, or Services, this
        Agreement is the complete and exclusive agreement between You and CaringCent
        regarding Your access to and use of the Platform, Content, and Services.&nbsp; This
        Agreement supersedes any prior agreement or proposal, oral or written, and any
        other communications between You and CaringCent relating to Your use of the
        Platform, Content, or Services as a User of the Platform.
      </Box>
      <ol>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            DEFINITIONS.
          </Box>
          <Box sx={{ margin: 1 }}>
            Terms used in this
            Agreement have the definitions given in this Agreement or, if not defined in
            this Agreement, have their plain English meaning as commonly interpreted in the
            United States.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            TERM.
          </Box>
          <Box sx={{ margin: 1 }}>
            This Agreement is
            entered into as of the earlier of the date You access or use the Site or the
            Platform, Content, or Services (the "<b>Effective Date</b>") and will
            continue until terminated as set forth herein.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            MODIFICATIONS.
          </Box>
          <Box sx={{ margin: 1 }}>
            CaringCent reserves
            the right, at any time, to modify the Platform, Content, or Services, with or
            without notice to You, by making those modifications available on the
            Platform.&nbsp; CaringCent also reserves the right, at any time, to modify the terms
            of this Agreement.&nbsp; CaringCent will inform You of the presence of any changes
            to this Agreement by posting those changes on the Platform or by providing You
            with notice through the Platform. Any modifications will be effective immediately
            upon posting on the Platform or delivery of such notice through the Platform.&nbsp;
            You may terminate this Agreement as set forth below if You object to any such
            modifications.&nbsp; However, You will be deemed to have agreed to any and all
            modifications through Your continued use of the Platform, Content, or Services
            following such notice.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            ELIGIBILITY.
          </Box>
          <Box sx={{ margin: 1 }}>
            The Platform,
            Content, and Services are intended for use by individuals 13 years of age and
            older.&nbsp; If You are a parent or guardian of a child under 13, then You may allow
            Your child to access the Platform, Content, or Services only under Your direct
            supervision.&nbsp; You will not allow Your child to access the Platform, Content, or
            Services other than under Your direct supervision and You will be solely
            responsible for all access to and use of the Platform, Content, or Services by
            Your child.&nbsp; If You are 13 or older but younger than 18, then You may access
            and use the Platform, Content, and Services only if Your parent or guardian
            accepts this Agreement on Your behalf.&nbsp; If You are a parent or guardian
            agreeing to this Agreement for the benefit of a child age 13 or older but under
            18, then You agree You will be solely responsible for all access to and use of
            the Platform, Content, or Services.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            ACCOUNTS.
          </Box>
          <ol>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Users.
              </Box>
              <Box sx={{ margin: 1 }}>
                You are permitted to
                access certain Content and Services without establishing a user account on the
                Platform (a "<b>User Account</b>"), provided that You have agreed to
                this Agreement.&nbsp; However, access to certain Content and Services requires that
                You establish a User Account on the Platform.&nbsp; Approval of Your request to
                establish a User Account will be at the sole discretion of CaringCent.&nbsp; Each
                User Account and the user identification and password for each User Account
                (the "<b>User Account ID</b>") is personal in nature.&nbsp; Each User
                Account is for Your personal use and each User Account ID may be used only by
                You alone.&nbsp; You may not distribute or transfer Your User Account or User
                Account ID or provide a third party with the right to access Your User Account
                or User Account ID.&nbsp; You are solely responsible for all use of the Platform,
                Content, and Services through Your User Account.&nbsp; You will ensure the security
                and confidentiality of Your User Account ID and will notify CaringCent
                immediately if Your User Account ID is lost, stolen, or otherwise compromised.&nbsp;
                You are fully responsible for all liabilities and damages incurred through the
                use of Your User Account or under Your User Account ID (whether lawful or
                unlawful) and any transactions completed through Your User Account or under
                Your User Account ID will be deemed to have been lawfully completed by You.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Registration.
              </Box>
              <Box sx={{ margin: 1 }}>
                In connection with establishing a User Account, You will be asked to submit
                certain information about Yourself ("<b>Registration Information</b>").&nbsp;
                You agree that: (a) all Registration Information You provide will be accurate,
                complete, and current; and (b) You will maintain and promptly update Your
                Registration Information to keep it accurate, complete, and current.&nbsp; You may
                not: (i) use or input Registration Information of another person with the
                intent to impersonate that person; and (ii) use or input Registration Information
                that CaringCent, in its sole discretion, deems offensive.
              </Box>
            </li>
          </ol>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            YOUR CONTENT.
          </Box>
          <Box sx={{ margin: 1 }}>
            You are solely responsible for all data, information, and other content (such
            as text, audio, video, photographs, illustrations, graphics, testimonials, and
            other media), including your User Account ID and Registration Information, that
            You provide or generate through Your access and use of the Platform, Content,
            or Services (collectively, “<b>Your Content</b>”).&nbsp; As between You and CaringCent,
            You retain ownership of Your Content.&nbsp; However, subject to any limitations
            included in the Privacy Policy, You grant CaringCent and its service providers
            a nonexclusive, royalty-free (unless otherwise agreed), perpetual, irrevocable,
            and fully sublicensable (including, without limitation, to Users and other
            third parties) right to use, copy, store, reproduce, modify, display, adapt,
            publish, translate, create derivative works from, distribute, and display (“<b>Use</b>”)
            Your Content for purposes of providing the Services to You and for use in
            making enhancements and improvements to the Platform.&nbsp; You represent and
            warrant that none of Your Content or the Use of Your Content by CaringCent: (a)
            violates this Agreement, the Privacy Policy, or any requirements under
            applicable laws, rules, and regulations (“<b>Laws</b>”); (b) is libelous,
            defamatory, obscene, abusive, pornographic, threatening, or an invasion of
            privacy; (c) constitutes an infringement, misappropriation, or violation of the
            IPR or other rights of any third party; (d) is illegal in any way or advocates
            illegal activity; (e) is an advertisement or solicitation of goods or services
            (unless You have entered into a separate advertiser agreement with CaringCent);
            (f) is false, misleading or inaccurate; or (g) is or could be considered junk
            mail, spam, a part of a pyramid scheme, a disruptive commercial message or
            disruptive advertisement.&nbsp; CaringCent is not responsible or liable for any
            deletion, correction, destruction, damage, loss or failure to store, restore or
            back-up any of Your Content.&nbsp; You agree that You have all right, title,
            interest and consent in Your Content necessary to allow CaringCent and its
            service providers to Use Your Content as set forth in the rights and licenses
            You grant to CaringCent under this Agreement.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            ACCESS.
          </Box>
          <ol>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                To the Platform
              </Box>
              <Box sx={{ margin: 1 }}>
                Subject to Your compliance with this Agreement, CaringCent will permit You to
                access and use the Platform, Content, and Services solely for lawful purposes
                and only in accordance with the terms of this Agreement and any other agreement
                You agree to with CaringCent before being given access to any specific aspects
                of the Platform.&nbsp; Any additional agreement is in addition to this Agreement and
                will govern Your use of the portions of the Platform to which the additional
                agreement applies in the event of a conflict between the terms of this
                Agreement and the additional agreement.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                To Other Users
              </Box>
              <Box sx={{ margin: 1 }}>
                The Platform may allow You to link, connect, or otherwise communicate with
                other Users through the Platform.&nbsp; By linking, connecting, or communicating
                with other Users, You are agreeing to allow those Users to communicate directly
                with You through the Platform.&nbsp; You agree that You are solely responsible for
                all communications between You and any other User through the Platform.&nbsp; Your
                extension or acceptance of a link, connection, or other communication with
                another User will serve as Your affirmative "opt in" to the
                disclosure of any of Your Content or other data or information (which may
                include Your personal information) that You provide to that other User.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                To Content
              </Box>
              <Box sx={{ margin: 1 }}>
                Unless otherwise noted on the Platform, all Content available through the
                Platform, including all text, audio, video, photographs, illustrations,
                graphics, testimonials, and other media, is owned by CaringCent, the Users
                providing User Content, or CaringCent’s other third party providers.&nbsp; You
                represent and warrant that You will comply with the Privacy Policy as to all
                Content available through the Platform.&nbsp; All Content is provided for
                informational purposes only and You are solely responsible for verifying the
                accuracy, completeness, and applicability of all Content and for Your use of
                any Content. &nbsp;Subject to Your compliance with this Agreement, You may access
                the Content solely for Your own personal and noncommercial purposes in
                connection with Your own use of the Platform and Services.&nbsp; CaringCent has not
                verified the accuracy of, and will not be responsible for any errors or
                omissions in any Content.&nbsp; Each User is solely responsible for any and all of
                its User Content.&nbsp; Because CaringCent does not control User Content, You
                acknowledge and agree that CaringCent is not responsible for any User Content.&nbsp;
                CaringCent makes no guarantees regarding the accuracy, currency, suitability,
                or quality of any User Content, and CaringCent assumes no responsibility for
                any User Content.&nbsp; Your interactions and transactions with other CaringCent
                Users are solely between You and such User.&nbsp; You agree that CaringCent will not
                be responsible for any loss or damage incurred as the result of any such
                interactions.&nbsp; If there is a dispute between You and any other User, CaringCent
                is under no obligation to become involved.&nbsp; Without limiting the foregoing, CaringCent
                will not be held liable to You or any other third party for any Content
                (including Your User Content) under a Federal Law called the Communications
                Decency Act or CDA, 47 U.S.C. § 230.&nbsp; Except as set forth in this Agreement,
                You are granted no licenses or other rights in or to any Content, or any IPR
                therein or related thereto.&nbsp; If You would like to use any Content in a manner
                not permitted by this Agreement, please contact CaringCent.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                To Third-Party Services
              </Box>
              <Box sx={{ margin: 1 }}>
                The Platform may provide You with the choice to access certain Services
                developed, provided, or maintained by other third-party service providers
                ("<b>Third Party Services</b>").&nbsp; In addition to the terms of this
                Agreement, Your access to and use of any Third Party Services is also subject
                to any other agreement You may agree to before being given access to the Third
                Party Services (each, a "<b>Third Party Service Agreement</b>").&nbsp; The
                terms of any Third Party Service Agreement (which may include payment of
                additional fees) will apply to the applicable Third Party Services provided
                under that Third Party Service Agreement in addition to the terms of this
                Agreement, but will not apply to any other Services You may access through CaringCent.&nbsp;
                Except as set forth in this Agreement, the terms of any Third Party Service
                Agreement will control in the event of a conflict between the terms of this
                Agreement and that Third Party Service Agreement. Among the various Third Party
                Services that CaringCent utilizes, CaringCent works with <Box
                  component='a'
                  href="https://www.plaid.com/"
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  Plaid
                </Box> to provide various Services to You.&nbsp; By accessing or using the
                Platform, Content, or Services, You expressly grant Plaid the right, power,
                and authority to access and transmit Your Content as reasonably necessary to
                provide the Services to You.
              </Box>
            </li>
          </ol>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            Donations.
          </Box>
          <ol>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Organizations.
              </Box>
              <Box sx={{ margin: 1 }}>
                Not all Organizations that a Donor may donate to through the Platform are charities to
                which You can make tax-deductible charitable contributions. &nbsp;Any donation you
                make to an Organization through the Platform may be processed by a service
                provider selected by CaringCent (see below) as described in this Agreement. &nbsp;You
                understand and acknowledge that CaringCent is not a charity. &nbsp;If You represent
                a charity eligible for tax-free contributions and would like to register to be
                listed as a charitable organization on the Platform, please contact us and we
                can help facilitate that process. &nbsp;As used in this Agreement, the term "Organization"
                does not refer to a charity, and you acknowledge that contributions to an
                Organization is not deductible for U.S. federal income tax purposes as
                charitable contributions unless otherwise indicated for a specific Organization
                on the Platform.&nbsp; Donors understand and agree that CaringCent does not verify
                whether any beneficiary Organization advertised as a non-profit actually has
                tax-exempt status by the U.S. Internal Revenue Service or any state agency.
                CaringCent encourages You to double-check on the tax-exempt status of any
                Organization claiming to be a non-profit before donating to an Organization.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Making Donations.
              </Box>
              <Box sx={{ margin: 1 }}>
                In order to contribute to an Organization, a Donor will be required to provide
                CaringCent payment card information (credit card or debit card), mobile services
                account, bank transfer mechanism or ACH, or other payment method. All Donors represent
                and warrant to CaringCent that such payment card information is true and that You
                are authorized to use the payment instrument. &nbsp;You agree that all donation
                payments are final and cannot be refunded under any circumstances. &nbsp;&nbsp;CaringCent
                does not warrant that donations will be used for any particular purpose and is
                not responsible for any misuse of the donations by the beneficiary Organization.&nbsp;
                By using the Platform, You understand and agree that CaringCent shall not be
                responsible for any losses or damages incurred as a result of the donations
                that You choose to make through the Platform. &nbsp;In the event of a dispute
                between Users (including but not limited to fundraisers, donors, beneficiaries,
                and third parties), You agree that CaringCent shall have no responsibility to
                You or to resolve any such dispute.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Fees.
              </Box>
              <Box sx={{ margin: 1 }}>
                CaringCent does not
                charge any Donor any upfront fees for registering a User Account on the
                Platform, and Organizations have a choice of programs that they can subscribe
                to based on the services they require.&nbsp; CaringCent retains a portion of each
                donation contributed to an Organization based on the Service Fee FAQ (<Box
                  component='a'
                  href="//www.caringcent.com/ServiceFeeFAQ"
                  target='_blank'>
                  www.caringcent.com/servicefeefaq</Box>) (“<b>Service Fee</b>”).&nbsp;
                Donors acknowledge that by making a donation to an Organization, Donor is
                agreeing to pay a Service Fee, in addition to any other terms relating to such
                transaction under this Agreement.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Third Party Accounts.
              </Box>
              <Box sx={{ margin: 1 }}>
                CaringCent uses a third party service provider,
                Plaid, to facilitate donations you make through the Platform.&nbsp; By accessing
                and using the Platform, You authorize CaringCent and its service providers to
                access third party sites designated by You, on your behalf, to retrieve
                information requested by You, and to register for accounts requested by You.&nbsp;
                For all purposes hereof, you hereby grant CaringCent and its service providers
                a limited power of attorney, and you hereby appoint CaringCent and its service
                providers as your true and lawful attorney-in-fact and agent, with full power
                of substitution and resubstitution, for You and in Your name, place and stead,
                in any and all capacities, to access third party internet sites, servers or
                documents, retrieve information, and use Your Content, all as described in this
                Agreement, with the full power and authority to do and perform each and every
                act and thing requisite and necessary to be done in connection with such
                activities, as fully to all intents and purposes as you might or could do in
                person.&nbsp; YOU ACKNOWLEDGE AND AGREE THAT WHEN CARINGCENT OR ITS SERVICE PROVIDER
                ACCESSES AND RETRIEVES INFORMATION FROM THIRD PARTY SITES, CARINGCENT AND ITS
                SERVICE PROVIDERS ARE ACTING AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF A
                THIRD PARTY.&nbsp; You agree that third party account providers shall be entitled to
                rely on the foregoing authorization, agency, and power of attorney granted by
                You.&nbsp; You understand and agree that the Platform is not endorsed or sponsored
                by any third party account providers accessible through the Platform.
              </Box>
            </li>
          </ol>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            TERMINATION.
          </Box>
          <Box sx={{ margin: 1 }}>
            This Agreement may
            be terminated by either party at any time, in that party’s sole discretion,
            upon notice to the other party as permitted under this Agreement.&nbsp; Upon
            termination or expiration of this Agreement for any reason: (a) all rights and
            licenses granted to You under this Agreement will terminate; (b) You will
            immediately cease all use of and access to the Platform and all Content and
            Services (including, without limitation, all Content You obtained prior to termination
            or expiration); and (c) CaringCent may, in its sole discretion, delete Your
            User Account and any of Your Content held by CaringCent.&nbsp; Sections 1
            (Definitions); 6 (Your Content); 8 (Donations); 11 (Platform Technology); 12
            (Ownership); 13 (Representations and Warranties); 14 (Indemnity); 15
            (Limitation on Liability); 16 (Data Privacy); 18 (Disputes); 19 (Governing Law
            and Venue); 20 (Notices); 21 (Linked Sites); and 22 (Additional Terms) will
            survive any expiration or termination of this Agreement.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            SUSPENSION.
          </Box>
          <Box sx={{ margin: 1 }}>
            Without limiting CaringCent’s
            right to terminate this Agreement, CaringCent may also suspend Your access to
            Your User Account and the Platform or any Content or Services (including Your
            Content), with or without notice to You, upon any actual, threatened or
            suspected breach of this Agreement or applicable Law or upon any other conduct
            deemed by CaringCent, in its sole discretion, to be inappropriate or
            detrimental to the Platform, Services, CaringCent, or any other User or third
            party.&nbsp; For example, we may temporarily “suspend” a User if his or her monthly
            credit card charge is denied for a certain period of months in a row, or if his
            or her User Account seems inactive for 3 continuous months.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            PLATFORM TECHNOLOGY.
          </Box>
          <Box sx={{ margin: 1 }}>
            The Platform, and the databases, software, hardware and other technology used
            by or on behalf of CaringCent to operate the Platform, and the structure,
            organization, and underlying data, information and software code thereof
            (collectively, the "<b>Technology</b>"), constitute valuable trade
            secrets of CaringCent.&nbsp; You will not, and will not permit any third party to:
            (a) access or attempt to access the Technology except as expressly provided in
            this Agreement; (b) use the Technology in any unlawful manner or in any other
            manner that could damage, disable, overburden or impair the Technology; (c) upload,
            transmit, or distribute any computer viruses, worms, or any software intended
            to damage or alter the Technology; (d) harvest, collect, gather, or assemble
            information or data regarding other Users, including e-mail addresses, without
            their consent; (e) alter, modify, reproduce, create derivative works of the
            Technology; (e) alter, obscure or remove any copyright, trademark, or any other
            notices that are provided on or in connection with the Technology; (f)
            distribute, sell, resell, lend, loan, lease, license, sublicense or transfer
            any of Your rights to access or use the Technology, or otherwise make the
            Technology available to any third party; (g) reverse engineer, disassemble,
            decompile, or otherwise attempt to derive the method of operation of the
            Technology; (h) attempt to circumvent or overcome any technological protection
            measures intended to restrict access to any portion of the Technology; (i)
            interfere with the operation or hosting of the Technology; or (j) introduce
            software or automated agents or scripts to the Technology so as to produce
            multiple accounts, generate automated searches, requests and queries, or to
            strip, scrape, deep link, or mine Content from the Technology (except that CaringCent
            grants the operators of public search engines revocable permission to use
            spiders to copy materials for the sole purpose of and solely to the extent
            necessary for creating publicly available searchable indices of the materials,
            but not caches or archives of such materials).
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            OWNERSHIP.
          </Box>
          <Box sx={{ margin: 1 }}>
            CaringCent retains
            all right, title, and interest, including, without limitation, all intellectual
            property rights, proprietary rights, rights of publicity, rights of privacy,
            and any and all other legal rights protecting data, information, or intangible
            property throughout the world, including, without limitation, any and all
            copyrights, trademarks, service marks, trade secrets, patent rights, moral
            rights, sui generis rights in databases, and contract rights (“<b>IPR</b>”), in
            and to the Technology and any additions, improvements, updates and
            modifications thereto. You receive no ownership interest in or to the
            Technology and You are not granted any right or license to use the Technology
            itself, apart from Your ability to access the Platform, Content, and Services
            under this Agreement.&nbsp; The CaringCent name, logo, and all product and service
            names associated with the Platform, Content, and Services are trademarks of CaringCent
            and its licensors and third party providers and You are granted no right or
            license to use them.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            REPRESENTATIONS AND WARRANTIES.
          </Box>
          <ol>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Mutual.
              </Box>
              <Box sx={{ margin: 1 }}>
                Each party hereby
                represents and warrants to the other party that: (a) it has the legal right and
                authority to enter into this Agreement; (b) this Agreement forms a binding
                legal obligation on behalf of such party; and (c) it has the legal right and
                authority to perform its obligations under this Agreement and to grant the
                rights and licenses described in this Agreement.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                Compliance with Laws
              </Box>
              <Box sx={{ margin: 1 }}>
                You acknowledge that
                the Platform is a general purpose online service and is not specifically
                designed to facilitate compliance with any specific Law.&nbsp; You acknowledge that
                You will access and use the Platform in compliance with all Laws applicable to
                You, Your Content and any other Content You may access through the Platform.&nbsp; CaringCent
                is not responsible for notifying You of any such Law, enabling Your compliance
                with any such Law, or for Your failure to comply.&nbsp; You represent and warrant to
                CaringCent that Your Content and Your use of and access to the Platform,
                including any Content or Services, will comply with all applicable Laws and
                will not cause CaringCent itself or any other User or third party to violate
                any applicable Laws.
              </Box>
            </li>
            <li>
              <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                No Warranties; Disclaimer
              </Box>
              <Box sx={{ margin: 1 }}>
                THE PLATFORM, CONTENT, AND SERVICES ARE PROVIDED "AS IS" AND "AS
                AVAILABLE."&nbsp; CARINGCENT AND ITS SERVICE PROVIDERS DO NOT WARRANT OR
                GUARANTEE THE ACCURACY, COMPLETENESS, OR ADEQUACY OF THE PLATFORM OR ANY
                CONTENT OR SERVICES AND DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE
                EXPRESSED IN THE CONTENT OR OTHER DATA, INFORMATION, OR USER CONTENT THAT MAY
                BE PROVIDED THROUGH THE PLATFORM.&nbsp; CARINGCENT AND ITS SERVICE PROVIDERS
                EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH
                REGARD TO THE PLATFORM, CONTENT, SERVICES AND OTHER SUBJECT MATTER OF THIS
                AGREEMENT, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, WITHOUT
                LIMITATION, ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE,
                MERCHANTABILITY, TITLE, OR NON-INFRINGEMENT. &nbsp;NO ORAL OR WRITTEN INFORMATION OR
                ADVICE GIVEN BY CARINGCENT, ITS EMPLOYEES, OR SERVICE PROVIDERS WILL INCREASE
                THE SCOPE OF, OR CREATE ANY NEW WARRANTIES IN ADDITION TO, THE WARRANTIES
                EXPRESSLY SET FORTH IN THIS SECTION 13.
              </Box>
              <Box sx={{ margin: 1 }}>
                CARINGCENT AND ITS SERVICE PROVIDERS MAKE NO
                WARRANTY THAT (a) THE PLATFORM WILL MEET YOUR REQUIREMENTS, (b) THE PLATFORM
                WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (c) THE RESULTS THAT MAY
                BE OBTAINED FROM THE USE OF THE PLATFORM WILL BE ACCURATE OR RELIABLE, (iv) THE
                QUALITY OF ANY CONTENT OR SERVICES PURCHASED OR OBTAINED BY YOU THROUGH THE
                PLATFORM WILL MEET YOUR EXPECTATIONS, OR (d) ANY ERRORS IN THE TECHNOLOGY WILL
                BE CORRECTED. ANY CONTENT OR SERVICES DOWNLOADED OR OTHERWISE OBTAINED THROUGH
                THE USE OF THE PLATFORM IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU ARE
                SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT
                RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
              </Box>
            </li>

          </ol>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            INDEMNITY
          </Box>
          <Box sx={{ margin: 1 }}>
            You hereby agree to
            indemnify, defend, and hold harmless CaringCent and its officers, directors,
            shareholders, affiliates, employees, agents, service providers, contractors,
            assigns, users, customers, providers, licensees, and successors in interest
            ("<b>Indemnified Parties</b>") from any and all claims, losses,
            liabilities, damages, fees, expenses and costs (including attorneys' fees,
            court costs, damage awards, and settlement amounts) that result from any claim
            or allegation (“<b>Claim</b>”) against any Indemnified Party arising in any
            manner from: (a) Your access to or use of the Platform, or any Content or
            Services; (b) Your Content or any access to or use thereof; (c) Your
            collection, use, and disclosure of any User Content, (d) Your violation of
            applicable Laws; and (e) Your breach of any representation, warranty, or other
            provision of this Agreement. &nbsp;CaringCent will use reasonable efforts to provide
            You with notice of any such claim or allegation, and CaringCent will have the
            right to participate in the defense of any such claim at its expense.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            LIMITATION ON LIABILITY
          </Box>
          <Box sx={{ margin: 1 }}>
            CARINGCENT AND ITS SERVICE PROVIDERS WILL NOT BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, UNDER
            ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
            (INCLUDING NEGLIGENCE OR OTHERWISE), ARISING IN CONNECTION WITH OR OUT OF THE
            USE OF THE PLATFORM, CONTENT, OR SERVICES, EVEN IF CARINGCENT OR ITS SERVICE
            PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING,
            WITHOUT LIMITATION, ANY LOSS OF YOUR CONTENT, OPPORTUNITY, REVENUES, PROFITS OR
            REPUTATION, BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE CONTENT, GOODS
            OR SERVICES. CARINGCENT’S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS
            AGREEMENT AND ALL CONTENT AND SERVICES PROVIDED UNDER THIS AGREEMENT OR THROUGH
            THE PLATFORM, WHETHER IN CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED $10.&nbsp;
            YOU AGREE THAT CARINGCENT WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE
            LIMITATIONS ON ITS LIABILITY.&nbsp; IN JURISDICTIONS WHERE LIMITATION OF LIABILITY
            FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES IS NOT PERMITTED, CARINGCENT’S
            LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            DATA
            PRIVACY
          </Box>
          <Box sx={{ margin: 1 }}>
            You expressly consent to the use and disclosure of Your personal information
            and other data and information as described in the Privacy Policy.
            Notwithstanding anything in the Privacy Policy, CaringCent will have the right
            to collect, extract, compile, synthesize, and analyze non-personal information
            (data or information that does not identify an entity or natural person as the
            source thereof) resulting from Your access to and use of the Platform, Services,
            or Content. &nbsp;To the extent any such non-personal information is collected or
            generated by CaringCent, the data and information will be solely owned by CaringCent
            and may be used by CaringCent, or its permitted service providers, for any
            lawful business purpose without a duty of accounting to You, provided that the
            data and information is used only in an aggregated form, without directly
            identifying You or any other entity or natural person as the source thereof.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            CLAIMS
            OF INFRINGEMENT
          </Box>
          <Box sx={{ margin: 1 }}>
            CaringCent respects Your copyrights and other intellectual property rights and
            those of other third parties.&nbsp; If You believe in good faith that Your
            copyrighted work has been reproduced on the Platform without Your authorization
            in a way that constitutes copyright infringement, You may notify our designated
            copyright agent by mail to:
          </Box>
          <Box sx={{
            marginLeft: '0.5in',
            marginBottom: '0in',
            fontSize: '10.0pt',
            fontFamily: '"Arial",sans-serif',
            color: 'black'
          }}>
            <Box>CaringCent</Box>
            <Box>Attn: CaringCent Copyright Agent</Box>
            <Box>5308 Cortaderia PL NE</Box>
            <Box>Albuquerque, NM&nbsp; 87111</Box>
            <Box
              component='a'
              href="mailto:info@caringcent.com">
              info@caringcent.com
            </Box>
            <Box>&nbsp;</Box>
          </Box>
          <Box sx={{ margin: 1 }}>
            Please provide the following information to CaringCent’s
            Copyright Agent: (a) the identity of the infringed work, and of the allegedly
            infringing work; (b) Your name, address, daytime phone number, and email
            address, if available; (c) a statement that You have a good-faith belief that
            the use of the copyrighted work is not authorized by the owner, his or her
            agent, or the law; (d) a statement that the information in the notification is
            accurate and, under penalty of perjury, that You are authorized to act on
            behalf of the owner; and (e) Your electronic or physical signature. CaringCent
            will take whatever action, in its sole discretion, it deems appropriate,
            including the removing the allegedly infringing work from the Platform.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            DISPUTES
          </Box>
          <Box sx={{ margin: 1 }}>
            Except as otherwise
            provided below, the parties will attempt to resolve all disputes,
            controversies, or claims arising under, out of, or relating to this Agreement,
            including the formation, validity, binding effect, interpretation, performance,
            breach or termination, of this Agreement and the arbitrability of the issues
            submitted to arbitration hereunder and non-contractual claims relating to this
            Agreement (each, a "<b>Dispute</b>"), in accordance with the
            procedures set forth in this Section. &nbsp;If any Dispute cannot be resolved
            through negotiations between the parties within 5 days of notice from one party
            to the other of the Dispute, such Dispute will be finally settled through
            binding arbitration under the arbitration of the American Arbitration
            Association ("<b>AAA</b>") then in effect (the "<b>Rules</b>").
            &nbsp;Either party may commence the arbitration by delivering a request for
            arbitration as specified in the Rules. &nbsp;The arbitration will be conducted
            before a sole neutral arbitrator selected by agreement of the parties. &nbsp;If the
            parties cannot agree on the appointment of a single arbitrator within 30 days
            (the "<b>Initial Period</b>") after either party to this Agreement
            delivers a request for arbitration, a neutral arbitrator will be selected as
            provided in the Rules. &nbsp;The arbitration will be conducted exclusively in the
            English language at a site specified by CaringCent in the State of Colorado,
            U.S.A. &nbsp;The award of the arbitrator will be the exclusive remedy of the parties
            for all claims, counterclaims, issues or accountings presented or plead to the
            arbitrator. &nbsp;The award of the arbitrators will require payment of the costs,
            fees, and expenses incurred by the prevailing party in any such arbitration by
            the non-prevailing party. &nbsp;Judgment upon the award may be entered in any court
            or governmental body having jurisdiction thereof. &nbsp;Any additional costs, fees,
            or expenses incurred in enforcing the award may be charged against the party
            that resists its enforcement.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            GOVERNING
            LAW AND VENUE
          </Box>
          <Box sx={{ margin: 1 }}>
            The interpretation of the rights and obligations of the parties under this
            Agreement, including, to the extent applicable, any negotiations, arbitrations
            or other proceedings hereunder, will be governed in all respects exclusively by
            the laws of the State of Colorado, U.S.A., as such laws apply to contracts
            between residents of Colorado without regard to conflict of laws provisions
            thereof.&nbsp; Subject to Section 18 (Disputes), each party will bring any action or
            proceeding arising from or relating to this Agreement exclusively in a federal
            or state court in the State of Colorado, U.S.A., and You irrevocably submit to
            the personal jurisdiction and venue of any such courts in any such action or
            proceeding brought in such courts by CaringCent.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            NOTICES
          </Box>
          <Box sx={{ margin: 1 }}>
            Unless otherwise
            specified in this Agreement, any notices required or allowed under this
            Agreement will be provided to CaringCent by postal mail to the address for CaringCent
            listed on the Platform. &nbsp;CaringCent may provide You with any notices required
            or allowed under this Agreement by sending You an email to any email address
            You provide to CaringCent in connection with Your User Account, provided that
            in the case of any notice applicable both to You and other Users of the
            Platform, CaringCent may instead provide such notice by posting on the
            Platform. &nbsp;Notices provided to CaringCent will be deemed given when actually
            received by CaringCent. &nbsp;Notice provided to You will be deemed given 24 hours
            after posting to the Platform or sending via e-mail, unless (as to e-mail) the
            sending party is notified that the e-mail address is invalid.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            Linked Sites
          </Box>
          <Box sx={{ margin: 1 }}>
            The Platform, Content, or Services may contain links to third-party sites or content
            that are not under the control of CaringCent. &nbsp;If You access a third-party site
            or content from the Platform or Services, then You do so at Your own risk and CaringCent
            is not responsible for any content on any linked site. You may establish a link
            to the Site, provided that the link does not state or imply any sponsorship or
            endorsement of Your site by CaringCent or any group or individual affiliated
            with CaringCent. &nbsp;You may not use on Your site any Content or marks appearing
            on the Site or Platform in establishing the link. &nbsp;You may not frame or
            otherwise incorporate into another site the content or other materials on the
            Platform without prior written consent.
          </Box>
        </li>
        <li>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            ADDITIONAL TERMS
          </Box>
          <Box sx={{ margin: 1 }}>
            Unless otherwise amended as provided herein, this Agreement will exclusively
            govern Your access to and use of the Platform, Content, and Services, and is
            the complete and exclusive understanding and agreement between the parties, and
            supersedes any oral or written proposal, agreement or other communication
            between the parties, regarding Your access to and use of the Platform, Content,
            and Services.&nbsp; Except as expressly set forth in this Agreement, this Agreement
            may be amended or modified only by a writing signed by both parties.&nbsp; All
            waivers by CaringCent under this Agreement must be in writing or later
            acknowledged by CaringCent in writing.&nbsp; Any waiver or failure by CaringCent to
            enforce any provision of this Agreement on one occasion will not be deemed a
            waiver by CaringCent of any other provision or of such provision on any other
            occasion.&nbsp; If any provision of this Agreement is held to be unenforceable, that
            provision will be removed to the extent necessary to comply with the law,
            replaced by a provision that most closely approximates the original intent and
            economic effect of the original to the extent consistent with the law, and the
            remaining provisions will remain in full force.&nbsp; The prevailing party in any
            lawsuit or proceeding arising from or related to this Agreement will be
            entitled to receive its costs, expert witness fees and reasonable attorneys’
            fees, including costs and fees on appeal.&nbsp; You agree that each of CaringCent’s
            service providers shall be considered a third party beneficiary of the above
            provisions, with all rights to enforce such provisions as if a service provider
            were a party to this Agreement.&nbsp; Neither this Agreement nor any rights or
            obligations of You hereunder may be assigned or transferred by You (in whole or
            in part and including by sale, merger, consolidation, or other operation of
            law) without the prior written approval of CaringCent.&nbsp; Any assignment in
            violation of the foregoing will be null and void.&nbsp; CaringCent may assign this
            Agreement to any party that assumes CaringCent’s obligations hereunder.&nbsp; The
            parties hereto are independent parties, not agents, employees or employers of
            the other or joint venturers, and neither acquires hereunder any right or
            ability to bind or enter into any obligation on behalf of the other.&nbsp; Any
            reference herein to "including" will mean "including, without limitation."&nbsp;
            Upon request from CaringCent, You agree to provide CaringCent with such
            documentation or records with respect to Your activities under this Agreement
            as may be reasonably requested for CaringCent to verify Your compliance with
            the terms of this Agreement and all applicable Laws.
          </Box>
        </li>
      </ol>
    </Box>

  )
}