import * as React from 'react'
import {
  Collapse,
  Box,
  Button
} from '@mui/material'

export default function Component(props) {
  const [expanded, setExpanded] = React.useState(false)
  const [buttonText, setButtonText] = React.useState(props.button_text || 'Click for More Information')

  const handleExpandClick = () => {
    setExpanded(!expanded)
    setButtonText(!expanded ? 'Show Less' : props.button_text || 'Click for More Information')
  }

  return (
    <>
      {props.above}
      <Box textAlign='center'>
        <Button
          onClick={handleExpandClick}
        >
          {buttonText}
        </Button>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {props.children}
        <Box textAlign='center'>
          <Button
            onClick={handleExpandClick}
          >
            {buttonText}
          </Button>
        </Box>
      </Collapse>
    </>
  )
}
