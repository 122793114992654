import * as React from 'react'
import { useLocation } from 'react-router-dom'
import context from 'util/context'
import {
  Paper,
  Box
} from '@mui/material'
import { useGlobalState } from 'store'

import Header from './Header'
import Footer from 'components/Footer'
import Jumbotron from './Jumbotron'
import Thermometer from './Thermometer'
import DonorGoal from './DonorGoal'
import FormFields from './FormFields'
import Messages from './Messages'
import Confirmation from './Confirmation'
import ErrorHandler from './ErrorHandler'
import ContactUs from './ContactUs'
import ProgrammerFunctions from './ProgrammerFunctions'
import TimeRemaining from './TimeRemaining'
import RivalThermometer from './RivalThermometer'

export default function Component() {
  const globalState = useGlobalState()
  const { status, fb } = globalState
  const params = context.getParams()
  const location = useLocation()

  if (!params || !params.client || !params.campaign) {
    const parts = location.pathname.split('/')
    params.client = parts[1]
    params.campaign = parts[2]
    params.pathname = location.pathname
  }


  const styles = {}

  if (fb.background_image_url) {
    styles.body_container = {
      backgroundImage: 'url("' + fb.background_image_url + '")',
      backgroundSize: '100% auto',
      top: '-7px',
      width: '100%',
      position: 'relative',
      display: 'inline-block'
    }
  } else {
    styles.body_container = {
      top: '-7px',
      width: '100%',
      position: 'relative',
      display: 'inline-block',
      background: fb.background_color || fb.background_css || 'white'
    }
    if (fb.background_color || fb.background_css) {
      styles.section_margin = {
        marginTop: '12px',
        marginLeft: '12px',
        marginRight: '12px'
      }
    } else {
      styles.section_margin = {
        marginTop: '0px',
        marginBottom: '1px',
        marginLeft: '0px',
        marginRight: '0px'
      }
    }
  }

  const doit = () => {
    if (status === 'PAYMENT_SUCCESS') {
      return (<>
        <Confirmation />
        <ProgrammerFunctions />
      </>)
    }
    if (status === 'ERROR') {
      return <ErrorHandler />
    }
    if (status.substring(0, 7) === 'CONTACT') {
      return (<>
        <ContactUs />
        <ProgrammerFunctions />
      </>)
    }
    if (status === 'DATASTORE') {
      return (
        <>
          <Paper sx={{
            marginTop: '40px',
            marginLeft: '40px',
            marginRight: '40px'
          }}>
            <Box sx={{
              padding: '12px'
            }}>
              <pre>
                {JSON.stringify(globalState, (key, value) => {
                  if (key === 'datatables') return '[data truncated...]'
                  return value
                }, 2)}
              </pre>
            </Box>
          </Paper>
          <ProgrammerFunctions />
        </>
      )
    }
    return (
      <>
        <Jumbotron />
        {fb.campaign_end_date || fb.campaign_goal_dollars || fb.campaign_goal_donors ? <Paper sx={{
          marginTop: '40px',
          marginLeft: '40px',
          marginRight: '40px'
        }}>
          <Box sx={{
            padding: '12px'
          }}>
            <Thermometer />
            <DonorGoal />
            <TimeRemaining />
            <RivalThermometer />
          </Box>
        </Paper> : null}
        <Messages />
        <FormFields />
        <ProgrammerFunctions />
        <Box sx={{
          margin: '40px',
        }} />
      </>
    )
  }

  return (
    <>
      <Box sx={{
        maxWidth: 1050,
        margin: 'auto'
      }}>
        <Header />
        <Box sx={styles.body_container}>
          {doit()}
        </Box>
        <Footer />
      </Box>
    </>
  )
}
