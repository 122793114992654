import * as React from 'react'
import { Box } from '@mui/material'

export default function SeoHeaders({ content, ...props }) {
    return (
        <>
            <Box {...props} >
                <div
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </Box>
        </>
    )
}
