import React from 'react'
import { useGlobalState } from 'store'
import {
  Alert,
  Box,
  FormLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from '@mui/material'
import context from 'util/context'

export const Component = (props) => {
  const globalState = useGlobalState()
  const { errors } = globalState.page

  const id = props.id || props.name
  const name = props.name || props.id

  const handleData = (input) => {

    if (context.isArray(input)) {
      return input.map(val =>
        <MenuItem
          key={val}
          value={val}
        >
          {val}
        </MenuItem>
      )
    }
    if (context.isObject(input)) {
      return Object.entries(input).map(val =>
        <MenuItem
          key={val[0]}
          value={val[0]}
        >
          {val[1]}
        </MenuItem>
      )
    }
    if (context.isString(input)) {
      return input.split(/\r?\n/).map(val =>
        <MenuItem
          key={val}
          value={val}
        >
          {val}
        </MenuItem>
      )
    }
    throw new Error('DropDown: Input is not a datatype I can handle')
  }

  return (
    <>
      <span error-id={name} />
      <FormControl
        fullWidth
      >
        <FormLabel component='legend' sx={{
          color: 'black !important',
          fontWeight: 'bold !important',
        }}>
          {props.label}    {
            props.required &&
            <Box
              variant='span'
              sx={{ color: 'red', fontSize: '.8em' }}>* Required</Box>
          }
        </FormLabel>
        <Select
          id={id}
          name={name}
          value={props.value || ''}
          onChange={props.onChange}
          fullWidth
          error={Boolean(errors[name])}
        >
          {handleData(props.values)}
        </Select>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>}
        {errors[name] &&
          <Alert severity='error'>{errors[name]}</Alert>}
      </FormControl>
    </>
  )
}

export default Component
