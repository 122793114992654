import * as React from 'react'
import { useGlobalActions } from 'store'
import PrivacyPolicy from 'apps/legal/privacypolicy'
import TermsOfService from 'apps/legal/termsofservice'
import {
  Box,
  Button,
  Grid,
} from '@mui/material'

const Footer = (props) => {
  const { globalActions } = useGlobalActions()
  function onShowContact(event) {
    globalActions.setStatus('contact')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2em 0',
        borderTop: '1px solid #666',
        backgroundColor: 'black',
        color: 'white',
        '& a': {
          color: '#41addd',
          textDecoration: 'none',
          '&:hover': {
            color: '#6cc0e5'
          }
        },
        position: 'relative',
        top: -7
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{
              textAlign: 'center',
              paddingTop: '5px',
              backgroundColor: 'black'
            }}>
              CaringCent ©{new Date().getFullYear()}&nbsp;
              |&nbsp;<TermsOfService variant='link'>Terms&nbsp;of&nbsp;Service</TermsOfService>&nbsp;
              |&nbsp;<PrivacyPolicy variant='link'>Privacy</PrivacyPolicy>&nbsp;
              |&nbsp;<Button variant='contained' color='primary' onClick={onShowContact}> Contact Us </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{
              textAlign: 'center',
              backgroundColor: 'black'
            }}>
              &nbsp;
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Footer
