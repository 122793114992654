import * as React from 'react'
import { useGlobalState } from 'store'
import {
  Typography,
  Grid,
  LinearProgress,
} from '@mui/material'

export const Component = ({ props }) => {
  const globalState = useGlobalState()
  const { fb } = globalState


  if (!fb.campaign_goal_dollars) return null
  return (
    <>

      <Grid container spacing={1} sx={{
        padding: '12px'
      }}>
        <Grid item xs={12}>
          <Typography
            variant='h4'
            color='secondary'
          >
            ${new Intl.NumberFormat().format(fb.thermometer.donated)}
          </Typography>
          <Typography
            variant='caption'
            color='primary'
          >
            Donated
          </Typography>
          <br />
          <Typography
            variant='h5'
            color='primary'
            align='right'
          >
            <br />
            {Math.ceil(100 * fb.thermometer.donated / fb.campaign_goal_dollars)}% of ${new Intl.NumberFormat().format(fb.campaign_goal_dollars)} goal
          </Typography>

          <LinearProgress
            variant='determinate'
            value={Math.ceil(100 * fb.thermometer.donated / fb.campaign_goal_dollars)}
            sx={{
              height: 20,
              borderRadius: 5,
              backgroundColor: 'lightgrey !important',
              '& .MuiLinearProgress-bar1Determinate': {
                backgroundColor: 'primary.main',
              },
            }}
          />
        </Grid>
      </Grid>

    </>
  )
}

export default Component
