import * as React from 'react'
import { useGlobalState } from 'store'
import {
  Typography,
  Grid,
  LinearProgress,
} from '@mui/material'


export const Component = ({ props }) => {
  const globalState = useGlobalState()
  const { fb } = globalState

  if (!fb.campaign_goal_donors) return null
  return (
    <>
      <Grid container spacing={1} sx={{
        padding: '12px'
      }}>
        <Grid item xs={12}>
          <Typography
            variant='h4'
            color='secondary'
            align='center'
          >
            {new Intl.NumberFormat().format(fb.thermometer.num_donors)} donors of {new Intl.NumberFormat().format(fb.campaign_goal_donors)} donor goal
          </Typography>
          <Typography
            variant='caption'
            color='primary'
          >

          </Typography>
          <br />
          <LinearProgress
            variant='determinate'
            value={Math.ceil(100 * fb.thermometer.num_donors / fb.campaign_goal_donors)}
            sx={{
              height: 20,
              borderRadius: 5,
              backgroundColor: 'lightgrey !important',
              '& .MuiLinearProgress-bar1Determinate': {
                backgroundColor: 'primary.main',
              },
            }}
          />
          <Typography
            color='primary'
            align='right'
            sx={{ position: 'relative' }}
          >
            {Math.ceil(100 * fb.thermometer.num_donors / fb.campaign_goal_donors)}%
          </Typography>


        </Grid>
      </Grid>
    </>
  )
}

export default Component
