import * as React from 'react'
import {
  Grid,
  TextField
} from '@mui/material'
import Recaptcha from 'components/Recaptcha'
import { useGlobal } from 'store'
import TermsOfService from 'apps/legal/termsofservice/Button'

export const Component = React.forwardRef((props, ref) => {
  const [globalState, globalActions] = useGlobal()

  const { form, errors } = globalState.page

  const password_rules = 'At least eight (8) characters, one upper, one lower, and one number or special character.'

  function onChange(event) {
    globalActions.setForm({
      formName: 'page',
      action: 'setConfig',
      name: 'is_processing',
      value: false
    })
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    globalActions.setForm({
      formName: 'page',
      action: 'set',
      name: name,
      value: value
    })
  }
  return (
    <>
      <Grid item xs={12}>
        <TextField
          ref={ref}
          required
          id='first_name'
          name='first_name'
          label='First Name'
          value={form.first_name}
          fullWidth
          autoComplete='fname'
          onChange={onChange}
          error={Boolean(errors.first_name)}
          helperText={errors.first_name}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='last_name'
          name='last_name'
          label='Last Name'
          value={form.last_name}
          fullWidth
          autoComplete='lname'
          onChange={onChange}
          error={Boolean(errors.last_name)}
          helperText={errors.last_name}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          id='email_address'
          name='email_address'
          label='Email Address'
          value={form.email_address}
          fullWidth
          autoComplete='emailaddress'
          onChange={onChange}
          error={Boolean(errors.email_address)}
          helperText={errors.email_address}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='Password'
          name='Password'
          label='Password'
          value={form.Password}
          fullWidth
          autoComplete='Password'
          onChange={onChange}
          error={Boolean(errors.Password)}
          helperText={errors.Password2 ? errors.Password2 + password_rules : password_rules}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id='Password2'
          name='Password2'
          label='Confirm Password'
          value={form.Password2}
          fullWidth
          autoComplete='Password2'
          onChange={onChange}
          error={Boolean(errors.Password2)}
          helperText={errors.Password2}
        />
      </Grid>
      <Grid item xs={12}>
        <br />
        <Recaptcha />
      </Grid>
      <Grid item xs={12}>
        <br />
        By clicking the Submit button, you agree to our{' '}
        <TermsOfService
          variant='link'
        >
          Terms Of Service
        </TermsOfService>
      </Grid>
    </>
  )
})

export default Component
