import * as React from 'react'

import {
  Alert
} from '@mui/material'

import 'braintree-web'
import DropIn from 'braintree-web-drop-in-react'
import { useGlobal } from 'store'

// Holds last configuration to paypal in order
// to decide wether to update the Braintree paypal
// config. when you update the config, it invalidates
// the currently selected paypal account, so we only want to
// update it if we have to.

let last = {}

export const Component = ({ handle, setHandle, ...props }) => {
  const [globalState, globalActions] = useGlobal()


  const {
    errors
  } = globalState.page

  const {
    fb,
    environment,
    braintree_client_token
  } = globalState

  const paypal = {}
  paypal.flow = 'vault'
  // paypal.amount = form.total_charge_amount || 0
  // paypal.currency = 'USD'
  paypal.offerCredit = false
  paypal.offerPayLater = false
  // paypal.intent = 'sale'
  paypal.displayName = fb.client + ' Donations'
  // paypal.requestBillingAgreement = true
  paypal.billingAgreementDescription = 'Description of the billng agreement to display to the customer'

  if (handle) {
    if (paypal.amount !== last.amount) {
      for (const [key, value] of Object.entries(paypal)) {
        handle.updateConfiguration('paypal', key, value)
      }
      last = paypal
    }
  }

  return (
    <>
      <span error-id='payment_method' />
      {errors.payment_method &&
        <Alert severity='error'>
          {errors.payment_method}
        </Alert>}
      <DropIn
        options={{
          authorization: braintree_client_token,
          card: {},
          paypal: paypal,
          dataCollector: {
            kount: { environment: environment === 'production' ? 'production' : 'sandbox' }
          }
        }}
        onInstance={(instance) => { setHandle(instance) }}
        onChange={() => { globalActions.setForm({ ...errors, ...{ payment_error: null } }) }}
      />
    </>
  )
}

export default Component
