import * as React from 'react'
import {
  Grid,
  TextField
} from '@mui/material'

import { useGlobal } from 'store'
export const Component = () => {
  const [globalState, globalActions] = useGlobal()

  const { form, errors } = globalState.page
  const { fb } = globalState

  function onChange(event) {
    globalActions.setForm({
      formName: 'page',
      action: 'setConfig',
      name: 'is_processing',
      value: false
    })
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    globalActions.setForm({
      formName: 'page',
      action: 'set',
      name: name,
      value: value
    })
  }
  const little = {
    xs: 12,
    sm: 4
  }
  const big = {
    xs: 12,
    sm: 12
  }

  let small = 0
  if (fb.pii_collect_postal_zipcode) {
    small++
  }
  if (fb.pii_collect_postal_state) {
    small++
  }
  if (fb.pii_collect_postal_city) {
    small++
  }

  switch (small) {
    case 0:
      break
    case 1:
      big.sm = 8
      little.sm = 4
      break
    case 2:
      little.sm = 6
      break
    case 3:
      little.sm = 4
      break
    default:
      break
  }

  return (
    <>
      {fb.pii_collect_first_name &&
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='first_name'
            name='first_name'
            label='First Name'
            value={form.first_name}
            fullWidth
            autoComplete='fname'
            onChange={onChange}
            error={Boolean(errors.first_name)}
            helperText={errors.first_name}
          />
        </Grid>}
      {fb.pii_collect_last_name &&
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='last_name'
            name='last_name'
            label='Last Name'
            value={form.last_name}
            fullWidth
            autoComplete='lname'
            onChange={onChange}
            error={Boolean(errors.last_name)}
            helperText={errors.last_name}
          />
        </Grid>}

      {fb.pii_collect_email_address &&
        <Grid item xs={12} sm={fb.pii_collect_phone_number ? 6 : 12}>
          <TextField
            required
            id='email_address'
            name='email_address'
            label='Email Address'
            value={form.email_address}
            fullWidth
            autoComplete='emailaddress'
            onChange={onChange}
            error={Boolean(errors.email_address)}
            helperText={errors.email_address}
          />
        </Grid>}

      {fb.pii_collect_phone_number &&
        <Grid item xs={12} sm={6}>
          <TextField
            id='phone_number'
            name='phone_number'
            label='Phone Number'
            value={form.phone_number}
            fullWidth
            autoComplete='phone'
            onChange={onChange}
            error={Boolean(errors.phone_number)}
            helperText={errors.phone_number}
          />
        </Grid>}

      {fb.pii_collect_postal_address &&
        <Grid item {...big}>
          <TextField
            required
            id='street_address'
            name='street_address'
            label='Street Address'
            value={form.street_address}
            fullWidth
            autoComplete='street-address'
            onChange={onChange}
            error={Boolean(errors.street_address)}
            helperText={errors.street_address}
          />
        </Grid>}
      {fb.pii_collect_postal_city &&
        <Grid item {...little}>
          <TextField
            required
            id='city'
            name='city'
            label='City'
            value={form.city}
            fullWidth
            autoComplete='city'
            onChange={onChange}
            error={Boolean(errors.city)}
            helperText={errors.city}
          />
        </Grid>}

      {fb.pii_collect_postal_state &&
        <Grid item {...little}>
          <TextField
            required
            id='state'
            name='state'
            label='State'
            value={form.state}
            fullWidth
            autoComplete='State'
            onChange={onChange}
            error={Boolean(errors.state)}
            helperText={errors.state}
          />
        </Grid>}

      {fb.pii_collect_postal_zipcode &&
        <Grid item {...little}>
          <TextField
            required
            id='postal_code'
            name='postal_code'
            label='Postal Code'
            value={form.postal_code}
            fullWidth
            autoComplete='postal-code'
            onChange={onChange}
            error={Boolean(errors.postal_code)}
            helperText={errors.postal_code}
          />
        </Grid>}

    </>
  )
}

export default Component
