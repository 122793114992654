import * as React from 'react'
import { useGlobal } from 'store'

import { useWindowDimensions } from 'util/hooks'

import {
  Alert
} from '@mui/material'

import ReCAPTCHA from 'react-google-recaptcha'

export const Component = (props) => {
  const [globalState, globalActions] = useGlobal()

  const { width } = useWindowDimensions()

  const {
    errors,
    config
  } = globalState.page
  console.log(globalState)
  console.log(config)

  function onChange(event) {
    globalActions.setForm({
      formName: 'page',
      action: 'setConfig',
      name: 'is_processing',
      value: false
    })
    globalActions.setForm({
      formName: 'page',
      action: 'set',
      name: 'recaptcha_token',
      value: event
    })
  }

  return (
    <>
      <span error-id='recaptcha_token' />
      {errors.recaptcha_token &&
        <Alert severity='error'>
          {errors.recaptcha_token}
        </Alert>}
      <ReCAPTCHA
        sitekey={config.recaptcha_key}
        onChange={onChange}
        size={width < 420 ? 'compact' : 'normal'}
      />

    </>
  )
}

export default Component
