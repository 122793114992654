import context from 'util/context'
import awsconfig from 'aws-exports'

const environment = awsconfig.aws_cloud_logic_custom[0].endpoint.split('/').pop()

const initialState = {

    status: '',

    toggles: {
        ShowPrivacyPolicy: false,
        ShowTermsOfService: false,
        ShowContactUs: false,
    },
    api_active: false,
    environment: environment,
    thermometer: {
        donated: 0,
        num_donors: 0
    },
    plaid: {
        plaid_link_token: '',
        env: 'sandbox'
    },

    page: {
        config: { // This is populated from the config file for this page on initial component mount.
            environment: environment,
            formBuilder: {
                spotOptions: {}
            },
            minimum_transaction_amount: 10,
            privacy_policy_url: '',

            // properties in all caps are used in formfields
            SHOW_PHONE_NUMBER: false,
            SHOW_STREET_ADDRESS: true,
            SHOW_REMAIN_ANONYMOUS: false,
            SHOW_PAY_PROCESSING_FEE: false,
            SHOW_IN_MEMORY_OF: false,
            PAY_PROCESSING_FEE_TEXT: 'I would like to pay the credit card processing fee',

            donation_amount_text: 'I would like to make a donation in the following amount:',
            donation_amounts: ['1000', '500', '250', '100', '50'],
            donation_initial_amount: '50', // Must be one of the values in donation_amounts

            show_goals: true,
            sub_campaign: '',
            secondary_end_date: 'Wed Jan 1 2020 12:00 PM',
            secondary_end_message: '',

            show_days_remaining: true,
            fb_app_id: '995890454184718'
        },
        form: {
            //email_address: process.env.NODE_ENV === 'production' ? '' : `test-cc-${Math.round(Math.random() * 200)}@paulperez.net`,
            email_address: process.env.NODE_ENV === 'production' ? '' : 'paul@paulperez.net',
            first_name: process.env.NODE_ENV === 'production' ? '' : 'Paul',
            last_name: process.env.NODE_ENV === 'production' ? '' : 'Perez',
            postal_code: process.env.NODE_ENV === 'production' ? '' : '90210',
            street_address: process.env.NODE_ENV === 'production' ? '' : 'Paris Hilton Rd.',
            phone_number: process.env.NODE_ENV === 'production' ? '' : '415-810-5639',
            city: process.env.NODE_ENV === 'production' ? '' : 'Beverly Hills',
            state: process.env.NODE_ENV === 'production' ? '' : 'CA',
            recaptcha_token: '',
            monthly_pledge_cap: '',
            total_charge_amount: 0,
            donation_frequency: ''
        },
        errors: {},
        spot: {
            selected: false,
            option: '',
            value: 0
        },
        golf: {},
        metrics: {},
        donations: {},
        line_items: { braintree: [], paypal: [] },
        tipping: {
            selected: false,
            option: '',
            value: 0
        }
    },
    // This is populated from the back end and contains all
    // the data for the form builder templates. 
    forms: {}
}

if (process.env.NODE_ENV === 'production') {
    context.recursiveAssign(initialState, {
        page: {
            config: {
                BTmerchantId: 'caringcent',
                help_phone: '844-276-8634',
                help_email: [
                    'paul@caringcent.com',
                    'jwills@caringcent.com',
                    'jcollins@caringcent.com',
                ],
                recaptcha_key: '6LfKOL8ZAAAAAAfjhgBvIHBK0WOV-jxeADgwAIEL'
            }
        }
    })
} else {
    context.recursiveAssign(initialState, {
        page: {
            config: {
                BTmerchantId: 'caringcent',
                help_phone: '505-600-1741',
                help_email: [
                    'paul@caringcent.com'
                ],
                recaptcha_key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
            }
        }
    })
}

export default initialState


