import * as React from 'react'
import { useGlobal } from 'store'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

export const Component = ({
  defaultValue,
  formName,
  helperText,
  label,
  metricName,
  secondaryText,
  values,
  show_other,
  orientation,
}) => {

  const [globalState, globalActions] = useGlobal()


  const metric = globalState[formName].metrics[metricName] || {
    selected: false,
    text: '',
    option: '',
    value: ''
  }

  function onChange(event) {
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    metric.errors = false
    metric.other_error = false

    if (name === 'option') {
      metric.selected = true
      if (value === 'Other') {
        metric.value = defaultValue
      } else {
        metric.value = value
      }
    }
    metric[name] = value
    metric.label = label
    metric.text = event.target.labels && event.target.labels[0] ? event.target.labels[0].innerText : label

    if (!metric.selected) {
      metric.value = ''
      metric.option = ''
      metric.text = ''
      metric.label = ''
      delete globalState[formName].metrics[metricName]
    } else if (metric.option !== 'Other' && !metric.value) {
      metric.value = defaultValue
      metric.option = defaultValue
    } else if (metric.option === 'Other' && !metric.value) {
      metric.other_error = true
    }

    globalActions.mergeState({
      [formName]: {
        metrics: {
          [metricName]: metric
        }
      }
    })
    globalActions.updateAmount()
    globalActions.updateLineItems()
  }

  const spacing = { xs: 12, sm: 4, md: 2 }

  const orient = orientation !== 'vertical' ? spacing : { xs: 12 }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={metric.selected}
                onChange={onChange}
                color='primary'
                name='selected'
              />
            }
            label={label}
            sx={{
              label: {
                fontWeight: '600',
                position: 'relative'
              }
            }}
          />
          {secondaryText && <Box sx={{
            position: 'relative',
            left: 30
          }}>{secondaryText}</Box>}
          {helperText && <Box sx={{
            position: 'relative',
            left: 30
          }}>{helperText}</Box>}
        </Grid>
        <Grid item xs={12}>
          <FormControl component='fieldset' sx={{ width: '100%' }}>
            <RadioGroup
              aria-label='donation'
              name='option'
              onChange={onChange}
              value={metric.option}
              sx={{ marginLeft: '35px' }}
            >
              <Grid container spacing={3}>
                {Object.entries(values).map(val =>
                  <Grid key={val[1]} item {...orient}>
                    <FormControlLabel
                      value={val[1]}
                      control={<Radio color='primary' />}
                      label={val[0]}
                    />
                  </Grid>
                )}
                {show_other && <>
                  <Grid item {...spacing} >
                    <FormControlLabel
                      value='Other'
                      control={<Radio color='primary' />}
                      label='Other'
                    />
                  </Grid>
                  {metric.option === 'Other' &&
                    <Grid item {...spacing}>
                      <TextField
                        required
                        name='value'
                        label='Other Amount'
                        fullWidth
                        type='number'
                        onChange={onChange}
                        value={metric.value}
                        error={metric.other_error}
                        helperText='Required'
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>}
                </>}
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default Component
