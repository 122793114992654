import * as React from 'react'
import { useGlobal } from 'store'
import {
  Box,
  Alert,
  FormLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from '@mui/material'


export const Component = (props) => {
  const [globalState, globalActions] = useGlobal()

  const { form, errors } = globalState.page

  const id = props.id || props.name
  const name = props.name || props.id

  function onChange(event) {
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    globalActions.setForm({
      formName: 'page',
      action: 'set',
      name: name,
      value: value
    })
  }

  return (
    <>
      <Box variant='span' error-id={name} />
      <FormControl
        fullWidth
      >
        <FormLabel component='legend' sx={{
          color: 'black !important',
          fontWeight: 'bold !important',
        }}>
          {props.label}    {
            props.required &&
            <Box
              variant='span'
              sx={{ color: 'red', fontSize: '.8em' }}>* Required</Box>
          }
        </FormLabel>

        <Select
          id={id}
          name={name}
          value={props.value || form[name] || ''}
          onChange={props.onChange || onChange}
          fullWidth
          error={Boolean(errors[name])}
        >
          {
            Object.entries(props.values).map(([key, val]) => {
              return <MenuItem key={key} value={key}>{val}</MenuItem>
            })
          }
        </Select>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>}
        {errors[name] &&
          <Alert severity='error'>{errors[name]}</Alert>}
      </FormControl>
    </>
  )
}

export default Component
