import { useEffect, useRef, useState } from 'react'

const useVisibleEffect = (callback, options) => {
    const targetRef = useRef(null)
    const [isVisible, setIsVisible] = useState(false)
    const [hasExecuted, setHasExecuted] = useState(false)

    useEffect(() => {
        if (isVisible && !hasExecuted) {
            callback()
            setHasExecuted(true)
        }
    }, [isVisible, hasExecuted, callback])

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries
                setIsVisible(entry.isIntersecting)
            },
            options
        )

        if (targetRef.current) {
            observer.observe(targetRef.current)
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current)
            }
        }
    }, [options])

    return targetRef
}
// Example usage:
const MyComponent = () => {
    const onVisible = () => {
        // Your side effect when the component becomes visible
        console.log('Component is visible!')
    }

    const targetRef = useVisibleEffect(onVisible, { threshold: 0.5 })

    return <div ref={targetRef}>Your component content goes here</div>
}

export default useVisibleEffect