import {
    ActionsOnly,
    NoActions,
    createSharedStoreHook
} from 'shared-store-hook'
import actions from './actions'
import initialState from './initialState'

export const useGlobal = createSharedStoreHook({
    actions,
    initialState,
})

export const useGlobalActions = () => useGlobal(ActionsOnly)

export const useGlobalState = () => useGlobal(NoActions)

export default useGlobal