import * as React from 'react'
import { useGlobal, useGlobalState } from 'store/useGlobal'
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

const pixel = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg=='

export const SpotDonation = ({ formName, ...props }) => {
  const [globalState, globalActions] = useGlobal()

  const { fb } = globalState
  const { errors, spot } = globalState[formName]

  const parseMe = fb.spot_option_values.includes('{') ? fb.spot_option_values : '{' + fb.spot_option_values + '}'
  const values = fb.spot_option_values ? JSON.parse(parseMe) : {}

  const orient = fb.spot_option_orientation !== 'vertical' ? { xs: 12, sm: 2 } : { xs: 12 }

  function onChange(event) {
    let { name, value, checked, type } = event.target

    if (type === 'checkbox') {
      value = checked
    }

    if (name === 'selected' &&
      checked === true &&
      Object.keys(values).length === 0) {
      spot.value = fb.spot_option_defaultvalue
      spot.option = 'Other'
    }

    if (name === 'option') {
      spot.selected = true
      if (value === 'Other') {
        spot.value = fb.spot_option_defaultvalue
      } else {
        spot.value = value
      }
    }
    spot[name] = value
    spot.value_error = ''
    if (!spot.selected) {
      spot.value = ''
      spot.option = ''
    } else if (spot.option !== 'Other' && !spot.value) {
      spot.value = fb.spot_option_defaultvalue
      spot.option = fb.spot_option_defaultvalue
    } else if (spot.option === 'Other' && !spot.value) {
      spot.value_error = 'Required'
    }
    errors.spot_donation = ''

    globalActions.mergeState({
      [formName]: { spot, errors }
    })
    globalActions.updateAmount()
    globalActions.updateLineItems()
  }

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={spot.selected}
              onChange={onChange}
              color='primary'
              name='selected'
            />
          }
          label={fb.spot_option_label || 'I would like to make a one-time donation now'}
        />
        <span error-id='spot_donation' />
        {errors.spot_donation &&
          <Alert severity='error'>
            {errors.spot_donation}
          </Alert>}
        <Grid item xs={12}>
          <FormControl
            component='fieldset'
            sx={{ width: '100%' }}
          >
            <RadioGroup
              aria-label='donation'
              name='option'
              onChange={onChange}
              value={spot.option}
              sx={{ marginLeft: '35px' }}
            >
              <Grid container spacing={2}>
                {Object.entries(values).map(val =>
                  <Grid key={val[1]} item {...orient}>
                    <FormControlLabel
                      value={val[1]}
                      control={<Radio color='primary' />}
                      label={val[0]}
                    />
                  </Grid>
                )}
                <Grid item {...orient}>
                  <FormControlLabel
                    value='Other'
                    control={<Radio color='primary' />}
                    label='Other'
                  />
                </Grid>
                {spot.option === 'Other' &&
                  <Grid item {...orient}>
                    <TextField
                      required
                      type='number'
                      name='value'
                      label='Other Amount'
                      fullWidth
                      onChange={onChange}
                      value={spot.value}
                      error={Boolean(spot.value_error)}
                      helperText={spot.value_error}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>}
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export const SpotDonationContainer = ({ formName, ...props }) => {

  const globalState = useGlobalState()
  const { fb } = globalState

  if (fb.spot_image) {
    return (
      <>
        <FormLabel component='legend' sx={{
          color: 'black !important',
          fontWeight: 'bold !important',
        }}>
          {fb.spot_option_title || 'One Time Donation'}
        </FormLabel>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Box
                component='img'
                alt='Gift'
                src={fb.spot_image || pixel}
                sx={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <SpotDonation formName='page' />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <FormLabel component='legend' sx={{
        color: 'black !important',
        fontWeight: 'bold !important',
      }}>
        {fb.spot_option_title || 'One Time Donation'}
      </FormLabel>
      <SpotDonation formName='page' />
    </>
  )
}

export default SpotDonationContainer
