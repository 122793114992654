import * as React from 'react'

import {
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material'

export default function FormDialog ({
  message,
  title,
  buttonLabel,
  onButtonPress = () => { }
}) {
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
    onButtonPress()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'> {title || 'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
