import React from 'react'
import Button from '@mui/material/Button'
import FacebookIcon from '@mui/icons-material/Facebook'

function FacebookShareButton({ url }) {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`

    const handleShare = () => {
        window.open(shareUrl, '_blank', 'noopener,noreferrer')
    }

    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={<FacebookIcon />}
            onClick={handleShare}
            sx={{
                width: '100%',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: '1',
                flexBasis: '50%',
                fontSize: '1.2em'
            }}
        >
            Share on Facebook
        </Button>
    )
}

export default FacebookShareButton