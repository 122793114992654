import * as React from 'react'
import { useGlobalState } from 'store'
import context from 'util/context'
import {
  Typography,
  Grid,
} from '@mui/material'

export const Component = ({ props }) => {
  const globalState = useGlobalState()
  const { fb } = globalState

  const endDate = fb.campaign_end_date
  const remaining = context.getTimeRemaining(endDate)

  if (!fb.campaign_end_date) return null
  return (
    <>
      <Grid container spacing={1} sx={{
        padding: '12px'
      }}>
        <Grid item xs={12}>
          <Typography
            variant='h4'
            color='secondary'
            align='center'
          >
            &nbsp;Campaign ends {fb.campaign_end_date}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h6'
            color='primary'
            align='center'
          >
            {remaining.days ? <>{remaining.days} Days</> : null} &nbsp;
            {remaining.hours ? <>{remaining.hours} Hours</> : null} &nbsp;
            {remaining.minutes ? <>{remaining.minutes} Minutes </> : null} &nbsp;
            Remaining
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Component
