import * as React from 'react'
import { useLocation } from 'react-router-dom'
import context from 'util/context'
import { useGlobal } from 'store'
import {
  Button,
} from '@mui/material'

export const Buttons = () => {
  const [globalState, globalActions] = useGlobal()
  const location = useLocation()

  const {
    status,
    environment
  } = globalState

  const {
    config
  } = globalState.page

  const styles = {
    action_button: {
      width: '100px',
      fontSize: '.6em',
      position: 'fixed',
      right: 2,
      opacity: 0.75,
      zIndex: 999
    },
    first: { top: 86 },
    second: { top: 132 },
    third: { top: 178 },
    fourth: { top: 224 },
    fifth: { top: 270 },
    sixth: { top: 316 }
  }


  function onPaymentSuccess(event) {
    globalActions.setStatus('payment_success')
    window.scrollTo(0, 0)
  }

  function onDataStore(event) {
    globalActions.setStatus('datastore')
    window.scrollTo(0, 0)
  }
  function onContactPage(event) {
    globalActions.setStatus('contact')
    window.scrollTo(0, 0)
  }

  function onDonateMain(event) {
    globalActions.setStatus('')
    window.scrollTo(0, 0)
  }

  function onSendReceipt(event) {
    globalActions.submitSendReceipt()
  }

  function onReloadForm(event) {
    const params = context.getParams()

    if (!params || !params.client || !params.campaign) {
      const parts = location.pathname.split('/')
      params.client = parts[1]
      params.campaign = parts[2]
      params.pathname = location.pathname
    }

    globalActions.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-form-builder',
      apiPayload: params,
      callback: (response) => {
        if (response.fb?.config) {
          globalActions.setPageConfig({
            ...globalState.config,
            ...response.fb.config
          })
        } else if (response.redirect) {
          context.redirect(response.redirect)
        }
      }
    })
  }

  return (
    <>
      {environment !== 'production' &&
        <>
          <Button
            variant='contained'
            color='primary'
            onClick={onReloadForm}
            disabled={config.is_processing}
            sx={{
              ...styles.action_button,
              ...styles.first,
            }}
          >
            Reload FormBuilder
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onSendReceipt}
            disabled={config.is_processing}
            sx={{
              ...styles.action_button,
              ...styles.second,
            }}

          >
            Send Receipt
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={onDataStore}
            disabled={config.is_processing}
            sx={{
              ...styles.action_button,
              ...styles.third,
            }}
          >
            Show DataStore
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onContactPage}
            disabled={config.is_processing}
            sx={{
              ...styles.action_button,
              ...styles.fourth,
            }}

          >
            Show Contact Us
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onPaymentSuccess}
            disabled={config.is_processing}
            sx={{
              ...styles.action_button,
              ...styles.fifth,
            }}
          >
            Show Confirmation
          </Button>

          {status !== '' && <Button
            variant='contained'
            color='primary'
            onClick={onDonateMain}
            disabled={config.is_processing}
            sx={{
              ...styles.action_button,
              ...styles.sixth,
            }}
          >
            Go To Main Donation Form
          </Button>}
        </>}
    </>
  )
}

export default Buttons
