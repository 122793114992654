import * as React from 'react'
import {
  Box,
  Typography
} from '@mui/material'
import { useGlobalState } from 'store'


export default function Component(props) {
  const globalState = useGlobalState()
  const { fb } = globalState

  return (
    <>
      {fb.header_banner_image_url
        ? <Box
          component='img'
          src={fb.header_banner_image_url}
          alt='Main Header'
          sx={{
            width: '100%',
            position: 'relative',
          }}
        />
        : <>
          <Typography
            variant='h3'
            sx={{
              position: 'relative',
              zIndex: 2
            }}
          >
            {fb.header_banner_text}
          </Typography>
        </>}
    </>
  )
}
